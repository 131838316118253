<template>
  <transition name="fade-ease-out-default" appear>
    <div class="premium-page-popup compare-popup">
      <div class="premium-page-popup-close" @click="$emit('closePopup')">
        <IconSVG :svg="IconCross" />
      </div>

      <div class="premium-page-popup-inner compare-popup-inner">
        <div class="premium-page-popup-title" v-html="getTranslation('compare_offers_title')" />
        <div class="compare-cols">
          <div
            v-for="(offer, i) in selectedOffersList"
            :key="offer + i"
            class="compare-col"
            :class="`compare-col-${i}`"
          >
            <div class="dropdown-col">
              <DropdownSequoia
                v-slot="{ value, opened, toggle }"
                :list="listOffers"
                :transform-native-select="true"
                theme-forced="dark"
                @select="selectAction[offer]"
              >
                <ButtonDropdown
                  width="auto"
                  size="large"
                  theme-forced="dark"
                  :placeholder="getTranslation('compare_offers_select_offer')"
                  :open="opened"
                  :value="value"
                  @click="toggle"
                />
              </DropdownSequoia>
            </div>

            <div class="compare-option">
              <div class="compare-cols-item-content">
                <div class="compare-price">
                  <span class="number" v-text="getOfferPrice(offer)" />
                  <span v-html="getOfferTranslationText(offer)" />
                </div>
              </div>
            </div>

            <div class="compare-option">
              <div
                class="compare-cols-item-title"
                v-html="i === 0 ? getTranslation('compare_offers_tv') : ''"
              />
              <div class="compare-cols-item-content">
                <IconSVG size="48" :svg="IconTV" class="compare-cols-item-content-icon" />
                <span
                  class="compare-cols-item-content-value"
                  v-html="getOfferCountChannelsText(offer)"
                />
              </div>
            </div>

            <div class="compare-option">
              <div
                class="compare-cols-item-title"
                v-html="i === 0 ? getTranslation('cinemas') : ''"
              />
              <div class="compare-cols-item-content">
                <IconSVG size="48" :svg="IconVod" class="compare-cols-item-content-icon" />
                <span class="compare-cols-item-content-value" v-html="getOfferVodText(offer)" />
              </div>
            </div>

            <div class="compare-option">
              <div
                class="compare-cols-item-title"
                v-html="i === 0 ? getTranslation('compare_offers_internet_speed') : ''"
              />
              <div class="compare-cols-item-content">
                <IconSVG size="48" :svg="IconSpeed" class="compare-cols-item-content-icon" />
                <span
                  class="compare-cols-item-content-value"
                  v-html="getOfferInternetSpeedText(offer)"
                />
              </div>
            </div>

            <div class="compare-option">
              <div
                class="compare-cols-item-title"
                v-html="i === 0 ? getTranslation('compare_offers_tv_stb') : ''"
              />
              <div class="compare-cols-item-content">
                <IconSVG size="48" :svg="IconStb" class="compare-cols-item-content-icon" />
                <span class="compare-cols-item-content-value" v-html="getOfferTvBoxText(offer)" />
              </div>
            </div>

            <div v-if="selectedOffers[offer]" class="compare-option">
              <div
                class="compare-cols-item-title"
                v-html="i === 0 ? getTranslation('compare_offers_channels') : ''"
              />

              <div
                v-for="genre in getMappedGenres(selectedOffers[offer].offerGenres)"
                :key="genre.id + genre.title"
                class="compare-col-channels-item dark"
              >
                <div class="compare-col-channels-name" :class="{ hide: i !== 0 }">
                  <div class="compare-col-channels-icon">
                    <IconSVG :svg="IconAir" :svg-path="getGenreIconPath(genre)" size="48" />
                  </div>
                  <div class="compare-col-channels-info">
                    <div class="h5" v-text="genre.title" />
                    <div class="body2">
                      <span v-html="getTranslation('premium_compare_max_channels')" />:&#32;
                      <span v-text="selectedOffers[offer].offerGenres[genre.id].length" />
                    </div>
                  </div>
                </div>

                <div class="compare-col-channels-counter">
                  <span v-text="getIncludeChannels(selectedOffers[offer].offerGenres[genre.id])" />
                  <div class="progressbar-line">
                    <div
                      class="fill"
                      :style="`width: ${getPercentChannels(
                        selectedOffers[offer].offerGenres[genre.id]
                      )}%;`"
                    />
                  </div>
                </div>

                <div class="compare-col-channels-list">
                  <ul>
                    <li
                      v-for="channel in getListChannelsByGenre(
                        selectedOffers[offer].offerGenres[genre.id],
                        genre.id
                      )"
                      class="compare-col-channels-list-item"
                      :class="{ active: channel.shouldInclude }"
                      v-text="getChannelTitle(channel)"
                    />
                  </ul>
                </div>

                <a
                  v-if="selectedOffers[offer].offerGenres[genre.id].length > countStartChannelList"
                  :class="{ opened: checkOpenedGenre(genre.id) }"
                  class="compare-col-more-link"
                  @click="openGenre(genre.id, i === 0)"
                >
                  <template v-if="i === 0">
                    <span v-html="getTranslation('compare_offers_more_channels')" />
                    <IconSVG :svg="IconTriangleDown" />
                  </template>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import PremiumCommon from 'src/components/premium-page/PremiumCommon';
import DropdownSequoia from 'src/components/ui/dropdown/DropdownSequoia.vue';
import ButtonDropdown from 'src/components/ui/buttons/ButtonDropdown.vue';
import IconCross from 'src/svg/cross.svg';
import IconSpeed from 'src/svg/speed.svg';
import IconTV from 'src/svg/tv.svg';
import IconStb from 'src/svg/stb.svg';
import IconVod from 'src/svg/vod.svg';
import IconTriangleDown from 'src/svg/triangle-down.svg';
import { TDropdownItem } from 'src/components/ui/dropdown/DropdownSequoia.types';
import { Prop } from 'vue-property-decorator';
import { TPremiumPageOfferEnhanced } from 'src/api/cms/types';
import { TChannelEnhanced } from 'src/api/channels/types';
import { getDeviceFlags } from 'src/utils/platform-detector';
import { getChannelTitle } from 'src/utils/channel';

const COUNT_START_CHANNEL_LIST = 12;

@Component({
  methods: { getChannelTitle },
  components: { DropdownSequoia, ButtonDropdown },
})
export default class OffersPopup extends PremiumCommon {
  IconCross = IconCross;
  IconSpeed = IconSpeed;
  IconTV = IconTV;
  IconStb = IconStb;
  IconVod = IconVod;
  IconTriangleDown = IconTriangleDown;
  countStartChannelList = COUNT_START_CHANNEL_LIST;

  selectedOffers: Record<string, TPremiumPageOfferEnhanced | null> = {
    offer1: null,
    offer2: null,
    offer3: null,
  };

  selectAction = {
    offer1: (item: TDropdownItem) => this.selectOffer(item, 'offer1'),
    offer2: (item: TDropdownItem) => this.selectOffer(item, 'offer2'),
    offer3: (item: TDropdownItem) => this.selectOffer(item, 'offer3'),
  };

  openedGenres: string[] = [];

  @Prop()
  offers!: TPremiumPageOfferEnhanced[];

  get isMobile() {
    return getDeviceFlags().isMobile;
  }

  get listOffers() {
    const offers: TDropdownItem[] = this.offers.map((offer) => ({
      id: offer.offerId,
      key: offer.offerId + offer.name,
      value: offer.name,
      selected: false,
    }));

    if (this.isMobile) {
      offers.unshift({
        value: this.getTranslation('compare_offers_select_offer'),
        selected: true,
        disabled: true,
        key: '',
      });
    }

    return offers;
  }

  get selectedOffersList() {
    return Object.keys(this.selectedOffers);
  }

  getOfferPrice(offer: string) {
    return this.selectedOffers?.[offer]?.price || '';
  }

  getOfferTranslationText(offer: string) {
    return this.selectedOffers?.[offer]?.price ? this.getTranslation('offer_union') : '—';
  }

  getOfferCountChannelsText(offer: string) {
    let count: undefined | number | '—' = '—';
    let text = this.getTranslation('channels_genitive');

    if (this.selectedOffers?.[offer]?.channels?.length) {
      count = this.selectedOffers[offer]?.channels.length;
    }

    if (typeof count === 'number') {
      text = this.getChannelsWord(count);
    }

    return `${count} ${text}`;
  }

  getOfferInternetSpeedText(offer: string) {
    const speed =
      this.selectedOffers[offer] && this.selectedOffers[offer]?.internetSpeed
        ? this.selectedOffers[offer]?.internetSpeed
        : `— ${this.getTranslation('internet_speed_union')}`;
    return `${speed}`;
  }

  getOfferTvBoxText(offer: string) {
    return this.selectedOffers[offer] && this.selectedOffers[offer]?.tvBox
      ? this.getTranslation('premium_compare_offers_inner')
      : '—';
  }

  getOfferVodText(offer: string) {
    return this.selectedOffers[offer] && this.selectedOffers[offer]?.vod
      ? this.getTranslation('premium_compare_offers_inner')
      : '—';
  }

  selectOffer(item: TDropdownItem, offer: string) {
    this.selectedOffers[offer] = this.offers.find((offer) => offer.id === item.key) || null;
  }

  getPercentChannels(channels: TChannelEnhanced[]) {
    return (this.getIncludeChannels(channels) / channels.length) * 100;
  }

  getIncludeChannels(channels: TChannelEnhanced[]) {
    return channels.filter((channel) => channel.shouldInclude).length;
  }

  getListChannelsByGenre(channels: TChannelEnhanced[], genre: string) {
    return this.checkOpenedGenre(genre) ? channels : channels.slice(0, COUNT_START_CHANNEL_LIST);
  }

  checkOpenedGenre(genreId: string) {
    return this.openedGenres.includes(genreId);
  }

  openGenre(genreId: string, action: boolean) {
    if (!action) {
      return;
    }

    const checkGenre = this.openedGenres.includes(genreId);

    if (!checkGenre) {
      this.openedGenres.push(genreId);
    } else {
      this.openedGenres = this.openedGenres.filter((item) => item !== genreId);
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';
@import 'src/styles/placeholders-and-mixins/truncate-lines';

.compare-popup {
  .compare-cols {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow: hidden;
    color: var(--c-dark-font-primary);
  }

  .compare-cols-item-title {
    width: 100%;
    height: 70px;
    padding: 16px 0;
    margin-bottom: 24px;
    border-bottom: 1px solid var(--c-dark-400);
    @include heading3;

    @include mobile-and-tablet {
      margin: 20px 16px 0;
    }

    @include mobile {
      margin: 20px 0 24px;
      white-space: nowrap;
    }
  }

  .compare-cols-item-content-value {
    @extend %truncate-after-1-line;
    @include heading5;

    @include mobile {
      display: block;
    }
  }

  .compare-cols-item-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    @include mobile {
      flex-direction: column;
      align-items: start;
    }

    .compare-cols-item-content-icon {
      color: #ffc125;
    }
  }

  .compare-col {
    position: relative;
    z-index: var(--z-1);
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: flex-start;
    width: 33.333%;

    &.compare-col-0 {
      .dropdown-col {
        justify-content: flex-start;
      }

      .compare-cols-item-title {
        width: 100%;
      }

      @include mobile {
        z-index: var(--z-2);
      }
    }

    &.compare-col-1 {
      .dropdown-col {
        justify-content: center;
      }

      .compare-cols-item-content {
        width: 96%;
        margin: 0 2%;

        @include mobile {
          width: 100%;
          margin: 0;
        }
      }
    }

    &.compare-col-2 {
      .dropdown-col {
        justify-content: flex-end;
      }

      .compare-cols-item-title {
        width: calc(100% - 16px);
      }

      .compare-cols-item-content {
        width: 96%;
        margin-left: 4%;
      }
    }

    .dropdown-col {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 34px;

      .menu-dropdown {
        width: 96%;
      }
    }

    .compare-option {
      width: 100%;
      padding: 24px 16px;

      @include mobile {
        padding-right: 0;
        padding-left: 0;
      }
    }

    @include mobile-and-tablet {
      justify-content: space-between;
    }

    .icon {
      margin-right: 24px;

      @include mobile {
        margin-bottom: 8px;
      }
    }

    @include mobile-and-tablet {
      width: 50%;
      padding: 28px 16px;

      &.dropdown {
        padding: 28px 0;
      }

      &:nth-child(3) {
        display: none;
      }
    }

    @include mobile {
      &.dropdown {
        padding: 16px 0 24px;
      }

      padding: 16px 0;
    }
  }

  .compare-price {
    @extend %truncate-after-1-line;

    .number {
      margin-right: 8px;
      @include heading2;

      @include mobile {
        @include heading3;
      }
    }
  }

  .compare-col-channels-item {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 48px;

    @include mobile-and-tablet {
      margin-bottom: 24px;
    }
  }

  .compare-col-channels-name {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-right: 16px;
    margin-bottom: 24px;

    &.hide {
      opacity: 0;
    }

    @include tablet {
      padding: 0 8px;
      margin-top: 24px;
    }

    @include mobile {
      padding: 0;
      margin-top: 16px;
    }

    .compare-col-channels-icon {
      width: 48px;
      margin-right: 24px;
      color: var(--c-light-brand);
    }

    .compare-col-channels-info {
      flex-wrap: wrap;
      width: calc(100% - 72px);

      @include mobile {
        white-space: nowrap;
      }

      .h5 {
        margin-bottom: 0;
      }

      .body2 {
        color: var(--c-dark-font-secondary);
      }
    }
  }

  .compare-col-channels-counter {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 16px;
    margin-bottom: 24px;
    @include heading5;

    @include mobile-and-tablet {
      margin-bottom: 16px;
    }

    @include mobile {
      padding: 0 4px;
    }
  }

  .progressbar-line {
    position: relative;
    width: 100%;
    height: 4px;
    margin-left: 12px;
    border-radius: 2px;

    .fill {
      position: absolute;
      right: 0;
      left: 0;
      height: 100%;
      background-color: var(--c-light-brand);
      border-radius: 2px;
    }
  }

  .compare-col-channels-list {
    padding: 0 16px;

    .compare-col-channels-list-item {
      margin-bottom: 12px;
      color: var(--c-dark-font-tertiary);
      @include body2;

      &.active {
        color: var(--c-dark-font-primary);
      }
    }

    @include mobile {
      padding: 0 4px;
    }
  }

  .compare-col-more-link {
    display: flex;
    width: 100%;
    height: 25px;
    padding: 0 0 0 16px;
    color: var(--c-dark-link-base);
    cursor: pointer;
    transition: transform 0.2s;
    @extend %truncate-after-1-line;
    @include body1;

    @include mobile {
      padding: 0;
      margin: 20px 0 0;
    }

    .icon {
      margin-right: 0;
    }

    span:first-child {
      border-bottom: 1px dashed var(--c-dark-link-base);
      @extend %truncate-after-1-line;
    }

    &:hover {
      span:first-child {
        border-bottom: 1px solid var(--c-dark-link-base);
      }
    }

    &.opened .icon {
      transform: rotate(180deg);
    }
  }
}
</style>
