<template>
  <div class="platforms-list">
    <div
      v-for="platform in platforms"
      :key="platform.title"
      v-observe-visibility="visibilityOptions()"
      class="platforms-list-item slide-animation"
      :class="[platform.type]"
    >
      <div class="platforms-list-item-icon">
        <ImageWithHash
          :src="`/public/images/premium-page/devices/${platform.image}`"
          :alt="platform.title"
        />
      </div>

      <div class="platforms-list-item-title" v-html="platform.title" />

      <div class="platforms-list-item-text" v-html="platform.text" />

      <div v-if="platform.links.length" class="platforms-list-item-links">
        <ul class="dark">
          <li v-for="link in platform.links" :key="link" v-html="link" />
        </ul>
      </div>

      <div class="platforms-list-item-logos">
        <ImageWithHash
          v-for="(logo, i) in platform.logos"
          :key="logo + i"
          :src="`/public/images/${logo}`"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import PremiumCommon from 'src/components/premium-page/PremiumCommon';
import ImageWithHash from 'src/components/ui/ImageWithHash.vue';

@Component({
  components: { ImageWithHash },
})
export default class PlatformsList extends PremiumCommon {
  platforms = [
    {
      title: this.getTranslation('premium_platforms_item_tv_title'),
      text: this.getTranslation('premium_platforms_item_tv_text'),
      image: 'smart-tv.svg',
      type: 'smarttv',
      links: [
        this.getTranslation('premium_platforms_link_smarttv_samsung'),
        this.getTranslation('premium_platforms_link_smarttv_lg'),
        this.getTranslation('premium_platforms_link_smarttv_android'),
      ],
      logos: [
        'premium-page/logos/lg-dark.svg',
        'premium-page/logos/samsung-dark.svg',
        'premium-page/logos/android-tv-dark.svg',
      ],
    },
    {
      title: this.getTranslation('premium_platforms_item_desktop_title'),
      text: this.getTranslation('premium_platforms_item_desktop_text'),
      image: 'browser.svg',
      type: 'browser',
      links: [this.getTranslation('premium_platforms_link_browser')],
    },
    {
      title: this.getTranslation('premium_platforms_item_phone_title'),
      text: this.getTranslation('premium_platforms_item_phone_text'),
      image: 'phones.svg',
      type: 'phones',
      links: [
        this.getTranslation('premium_platforms_link_phone_ios'),
        this.getTranslation('premium_platforms_link_phone_android'),
      ],
      logos: ['icons/store-badges/app-store-dark.svg', 'icons/store-badges/google-play-dark.svg'],
    },
    {
      title: this.getTranslation('premium_platforms_item_tvbox_title'),
      text: this.getTranslation('premium_platforms_item_tvbox_text'),
      image: 'stb.svg',
      type: 'stb',
      links: [
        this.getTranslation('premium_platforms_link_stb_android'),
        this.getTranslation('premium_platforms_link_stb_dune'),
        this.getTranslation('premium_platforms_link_stb_eltex'),
        this.getTranslation('premium_platforms_link_stb_mag'),
      ],
      logos: [
        'premium-page/logos/android-stb-dark.svg',
        'premium-page/logos/apple-tv-dark.svg',
        'premium-page/logos/dune-hd-dark.svg',
        'premium-page/logos/eltex-dark.svg',
        'premium-page/logos/mag-dark.svg',
        'premium-page/logos/tvip-dark.svg',
      ],
    },
  ];
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';
@import 'src/styles/pages/premium-page/premium-common';

.platforms-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  max-height: 1500px;

  @include tablet-l {
    max-height: 1500px;
  }

  @include tablet-s {
    max-height: 1770px;
  }

  @include mobile {
    flex-direction: row;
    max-height: none;
  }

  .platforms-list-item {
    position: relative;
    width: 49%;
    padding: 72px 48px;
    margin-right: 2%;
    margin-bottom: 32px;
    background-color: var(--c-dark-300);

    @include tablet {
      padding: 7.6% 5.3%;
      margin-bottom: 24px;
    }

    @include mobile {
      width: 100%;
      padding: 47px 32px;
      margin-right: 0;
      margin-bottom: 24px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 8px;
      background-image: linear-gradient(91deg, #ffc000 1%, #f93);
    }

    .platforms-list-item-icon {
      max-width: 64px;
      margin-bottom: 16px;

      img {
        display: block;
        width: 100%;
      }
    }

    .platforms-list-item-title {
      margin-bottom: 32px;
      color: var(--c-dark-font-primary);
      word-wrap: break-word;
      @include heading2;

      @include tablet-l {
        margin-bottom: 25px;
      }

      @include mobile {
        margin-bottom: 20px;
      }
    }

    .platforms-list-item-text {
      margin-bottom: 32px;
      color: var(--c-dark-font-primary);

      @include mobile-and-tablet {
        margin-bottom: 25px;
      }
    }

    .platforms-list-item-links {
      width: 100%;
      margin-bottom: 32px;

      @include mobile {
        margin-bottom: 25px;
      }

      li {
        display: block;
        margin-bottom: 8px;
        word-wrap: break-word;
      }

      a {
        @include mobile {
          margin-bottom: 12px;
        }
      }
    }

    .platforms-list-item-logos {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      img {
        max-height: 24px;
        margin-right: 24px;
        margin-bottom: 16px;

        @include mobile {
          max-height: 32px;
          margin-right: 16px;
          margin-bottom: 8px;
        }
      }

      .store-app-icon {
        max-height: 40px;
        margin-right: 16px;
      }
    }

    &.phones {
      .platforms-list-item-logos {
        img {
          max-height: 48px;
        }
      }
    }
  }
}
</style>
