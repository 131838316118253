<template>
  <div class="simple-text-block slide-animation">
    <div class="inner">
      <div class="title" v-html="title" />
      <div class="text" v-html="text" />
    </div>
  </div>
</template>

<script lang="ts">
import { SequoiaComponent } from 'src/mixins';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class SimpleTextBlock extends SequoiaComponent {
  @Prop()
  title?: string;

  @Prop()
  text?: string;
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';
@import 'src/styles/pages/premium-page/premium-common';

.simple-text-block {
  width: 100%;

  .inner {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1216px;
    padding: 0 64px;
    margin: 0 auto;

    @include tablet {
      padding: 0 48px;
    }

    @include mobile {
      padding: 0 24px;
    }

    .title {
      width: 50%;
      padding-right: 12%;
      color: var(--c-dark-font-primary);
      word-wrap: break-word;
      @include heading2;

      @include desktop-s {
        padding-right: 5%;
      }

      @include tablet-l {
        padding-right: 2%;
      }

      @include mobile {
        width: 100%;
        padding-right: 0;
        margin-bottom: 24px;
      }
    }

    .text {
      width: 50%;
      color: var(--c-dark-font-primary);
      @include headline2;

      @include mobile-and-tablet {
        @include body2;
      }

      @include desktop-s {
        padding-left: 2%;
      }

      @include tablet-l {
        padding-left: 1%;
      }

      @include mobile {
        width: 100%;
        padding-right: 0;
      }
    }
  }
}
</style>
