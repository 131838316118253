import uniq from 'lodash/uniq';
import cloneDeep from 'lodash/cloneDeep';
import { COMPONENT_TYPES, ENABLE_CYPRESS_MOCK, GTM_COUNTERS } from 'src/constants';
import * as api from 'src/api';
import { getEnvVars } from 'src/config';
import { getLogger } from 'lightlog';
import { TVodSourceBe } from 'src/store/vod/types';
import { getByLanguage } from 'src/utils/language';
import { prependWithBeBaseUrl } from 'src/utils/url';
import { isOkObject } from 'src/utils/object';
import { Request } from 'express-serve-static-core';
import { TImageCMS } from 'src/store/common/types';
import { TChannelCollection } from 'src/store/channel-collections/types';
import { TGenreIcon } from 'src/store/types';
import {
  TPackages,
  TPage,
  TPremiumPageProvider,
  TRedirect,
  TReviews,
  TSeoRule,
} from 'src/api/cms/types';

const log = getLogger('serverUtils');

let siteConfig: Record<any, any> = {};

const empty = (value: any) => !(value && !!value.length);

const prepareFile = (fileId: string) => {
  if (empty(fileId)) {
    return undefined;
  }

  const file = global.cmsData?.files?.[fileId];

  for (const lang in file) {
    if (file.hasOwnProperty(lang)) {
      if (!isOkObject(file[lang])) {
        continue;
      }
      file[lang].url = prependWithBeBaseUrl(file[lang].url);
    }
  }
  return file || {};
};

const prepareContentBlock = (contentBlockId: string) => {
  return !empty(contentBlockId) ? global.cmsData.contentBlocks[contentBlockId] : {};
};

const prepareTranslation = (translationId: string) => {
  return !empty(translationId) ? global.cmsData.translations[translationId] : undefined;
};

export const prepareFaviconsPaths = (faviconPaths: any) => {
  const prefix = prependWithBeBaseUrl(`/upload/favicon`);
  const faviconFullPaths: Record<any, any> = {};
  for (const icon in faviconPaths) {
    if (faviconPaths.hasOwnProperty(icon)) {
      faviconFullPaths[icon] = `${prefix}/${faviconPaths[icon]}`;
    }
  }
  return faviconFullPaths;
};

export const calculateLanguageCode = (req: Request, { languages, locales }: any) => {
  const langFromUrl = req.url.split('/')[1];
  //условие для промо страницы по задаче SEQ-1241
  const languageCode =
    req.url === '/promo' && getEnvVars().wlAppId === 'kt'
      ? 'kk'
      : locales.includes(langFromUrl)
      ? langFromUrl
      : global.cmsData.defaultLanguage || 'ru';

  if (locales.indexOf(languageCode) !== -1) {
    return languageCode;
  }

  const acceptsLanguages = uniq(req.acceptsLanguages().map((lang: any) => lang.slice(0, 2)));

  // first, search by locale names themselves
  for (const acceptedLangCode of acceptsLanguages) {
    if (locales.indexOf(acceptedLangCode) !== -1) {
      return acceptedLangCode;
    }
  }

  // if not found, try to search in 'forWhichLocales' arrays in config languages
  for (const acceptedLangCode of acceptsLanguages) {
    for (const language of languages) {
      if (!Array.isArray(language.forWhichLocales) || !language.forWhichLocales.length) {
        continue;
      }
      if (language.forWhichLocales.indexOf(acceptedLangCode) > -1) {
        return language.locale;
      }
    }
  }

  // if still not found, return default value
  return (locales[0] ? locales[0].locale : undefined) || 'ru';
};

function prepareComponents(allComponents: any) {
  const preparedComponents = [];
  for (const component of allComponents) {
    const { type, componentName } = component;
    if (!type || !componentName) {
      log.error(
        'Cannot proceed preparing the component. Invalid (empty) type or componentName',
        component
      );
      continue;
    }

    const content = cloneDeep(component.content);

    if (type === COMPONENT_TYPES.Advantages) {
      content.contentBlock = prepareContentBlock(content.contentBlockId);
      if (Array.isArray(content.advantages)) {
        content.advantages.forEach((advantage: any) => {
          advantage.contentBlock = prepareContentBlock(advantage.contentBlockId);
          advantage.image = prepareFile(advantage.imageId);
        });
      } else {
        log.error('advantage is not an array. advantage:', content.advantage);
      }
    } else if (type === COMPONENT_TYPES.BackgroundWithText) {
      content.contentBlock = prepareContentBlock(content.contentBlockId);
      content.image = prepareFile(content.imageId);
    } else if (type === COMPONENT_TYPES.Button) {
      content.translation = prepareTranslation(content.translationId);
    } else if (type === COMPONENT_TYPES.ButtonFileDownload) {
      content.name = prepareTranslation(content.name);
      content.translation = prepareTranslation(content.translationId);
      content.file = prepareFile(content.fileId);
    } else if (type === COMPONENT_TYPES.Device) {
      content.image = prepareFile(content.imageId);
      content.contentBlock = prepareContentBlock(content.contentBlockId);
    } else if (type === COMPONENT_TYPES.HeaderSequoia) {
      content.logoLight = prepareFile(content.logoLight);
      content.logoDark = prepareFile(content.logoDark);
    } else if (type === COMPONENT_TYPES.Accordion) {
      for (const item of content.items) {
        item.title = prepareTranslation(item.titleId);
        item.contentBlock = prepareContentBlock(item.contentBlockId);
      }
    } else if (type === COMPONENT_TYPES.HowToWatch) {
      if (Array.isArray(content.steps)) {
        content.steps.forEach((step: any) => {
          step.contentBlock = prepareContentBlock(step.contentBlockId);
          step.image = prepareFile(step.imageId);
        });
      } else {
        log.error(
          `Component HowToWatch#${componentName} steps is not an array. steps:` + content.steps
        );
      }
    } else if (type === COMPONENT_TYPES.ImageSlider) {
      for (const slide of content.images) {
        slide.image = prepareFile(slide.imageId);
        slide.title = prepareTranslation(slide.titleId);
      }
    } else if (type === COMPONENT_TYPES.ImageTextButton) {
      content.image = prepareFile(content.imageId);
      content.contentBlock = prepareContentBlock(content.contentBlockId);
      if (content.button.show) {
        content.button.translation = prepareTranslation(content.button.translationId);
      }
    } else if (type === COMPONENT_TYPES.TabPanel) {
      for (const tab of content.tabs) {
        const tabComponent = allComponents.find(
          (component: any) => component.componentName === tab.tabName
        );
        if (!tabComponent) {
          tab.content = null;
        } else {
          tab.content = tabComponent.content;
          tab.content.image = prepareFile(tab.content.imageId);
          tab.content.title = prepareTranslation(tab.content.titleId);
        }
      }
    } else if (type === COMPONENT_TYPES.TextOnly) {
      content.contentBlock = prepareContentBlock(content.contentBlockId);
    } else if (type === COMPONENT_TYPES.TvWizard) {
      content.title = prepareTranslation(content.titleId);
      content.imageLight = prepareFile(content.imageLight?.imageId);
      content.imageDark = prepareFile(content.imageDark?.imageId);
      content.availableText = prepareTranslation(content.availableText);
      content.unavailableText = prepareTranslation(content.unavailableText);
      content.SamsungInstructions = prepareContentBlock(content.SamsungInstructions);
      content.LGInstructions = prepareContentBlock(content.LGInstructions);
      content.AndroidInstructions = prepareContentBlock(content.AndroidInstructions);
      content.UnavailableInstructions = prepareContentBlock(content.UnavailableInstructions);
    } else if (type === COMPONENT_TYPES.YoutubeVideo) {
      content.translation = prepareTranslation(content.translationId);
      content.image = prepareFile(content.imageId);
    } else if (type === COMPONENT_TYPES.AboutPage) {
      content.title = prepareTranslation(content.titleId);
      content.image = prepareFile(content.imageId);
      for (const banner of content.banners) {
        const bannerComponent = allComponents.find(
          (component: any) => component.componentName === banner.bannerName
        );
        if (!bannerComponent) {
          banner.content = null;
        } else {
          banner.content = bannerComponent.content;
          banner.content.title = prepareTranslation(banner.content.titleId);
        }
      }
    } else if (type === COMPONENT_TYPES.Banner) {
      content.title = prepareTranslation(content.titleId);
      content.iconTop = prepareFile(content.iconTop);
      content.iconBottom = prepareFile(content.iconBottom);
    } else if (type === COMPONENT_TYPES.FooterComponent) {
      content.logoLight = prepareFile(content.logoLight);
      content.logoDark = prepareFile(content.logoDark);
      if (Array.isArray(content.menu)) {
        content.menu.forEach((menuItem: any) => {
          menuItem.title = prepareTranslation(menuItem.titleId);
          menuItem.items.forEach((link: any) => {
            link.translation = prepareTranslation(link.translationId);
          });
        });
      }
      if (Array.isArray(content.social)) {
        content.social.forEach((socialItem: any) => {
          socialItem.imageId = prepareFile(socialItem.imageId);
          socialItem.imageLight = prepareFile(socialItem.imageLight);
          socialItem.imageDark = prepareFile(socialItem.imageDark);
        });
      }

      if (Array.isArray(content.storeButtons)) {
        content.storeButtons.forEach((button: any) => {
          button.imageId = prepareFile(button.imageId);
          button.imageLight = prepareFile(button.imageLight);
          button.imageDark = prepareFile(button.imageDark);
        });
      }
      content.copyright = prepareContentBlock(content.copyright.contentBlockId);
      content.feedback = prepareContentBlock(content.feedback.contentBlockId);
    }
    preparedComponents[component.componentName] = { ...component, content };
  }
  return preparedComponents;
}

export const prepareVodSourceBe = (sourcesBe: Array<TVodSourceBe>) => {
  return sourcesBe.map((source: TVodSourceBe) => {
    source.imageTileVod = prepareFile(source.imageTileVod);
    source.customTitle = prepareTranslation(source.customTitleId);
    source.logo = prepareFile(source.logoId) || {
      ru: { url: `https://via.placeholder.com/128x69.png?text=${source.id.toUpperCase()}` },
    };
    source.logoTilePoster = prepareFile(source.logoTilePoster as unknown as string);
    source.logoTilePosterDark = prepareFile(source.logoTilePosterDark as unknown as string);
    source.fullTitles = {};

    return source;
  });
};

export const prepareQSConfig = (methods: any) => {
  Object.keys(methods).forEach((methodName) => {
    const method = methods[methodName];

    Object.keys(method).forEach((propertyName) => {
      const property = method[propertyName];

      if (property.translationId || property.translationId === '') {
        property.translation = prepareTranslation(property.translationId);
      } else if (property.contentBlockId) {
        property.contentBlock = prepareContentBlock(property.contentBlockId);
      }
    });
  });

  return methods;
};

export const preparePremiumPages = (providers: TPremiumPageProvider[]) => {
  return providers.map((provider) => {
    const banners = provider.banners.map((banner) => {
      return {
        ...banner,
        imageId16x4: typeof banner.imageId16x4 === 'string' ? prepareFile(banner.imageId16x4) : '',
        imageId16x9: typeof banner.imageId16x9 === 'string' ? prepareFile(banner.imageId16x9) : '',
      };
    });
    const offers = provider.offers.map((offer) => ({
      ...offer,
      image: typeof offer.image === 'string' ? prepareFile(offer.image) : '',
      icon: typeof offer.icon === 'string' ? prepareFile(offer.icon) : '',
    }));
    return {
      ...provider,
      logo: provider.logo ? prepareFile(provider.logo) : '',
      banners,
      offers,
    };
  });
};

const prepareSeoRules = (seoRules: TSeoRule[]): Record<string, TSeoRule> => {
  return seoRules.reduce((rules: Record<string, TSeoRule>, rule) => {
    rules[rule.url] = {
      ...rule,
      metaTitle: typeof rule.metaTitle === 'string' ? prepareTranslation(rule.metaTitle) : '',
      metaDescription:
        typeof rule.metaDescription === 'string' ? prepareTranslation(rule.metaDescription) : '',
      metaKeywords:
        typeof rule.metaKeywords === 'string' ? prepareTranslation(rule.metaKeywords) : '',
    };
    return rules;
  }, {});
};

const prepareRedirects = (
  redirects: TRedirect[]
): {
  regular: TRedirect[];
  fromPath: Record<string, TRedirect>;
} => {
  return redirects.reduce(
    (redirects: { regular: TRedirect[]; fromPath: Record<string, TRedirect> }, redirect) => {
      const newRedirect = { from: redirect.from, to: redirect.to };
      if (redirect.regular) {
        redirects.regular.push(newRedirect);
      } else {
        redirects.fromPath[redirect.from] = newRedirect;
      }

      return redirects;
    },
    { regular: [], fromPath: {} }
  );
};

const prepareReviews = (reviews: TReviews): TReviews => {
  if (!Array.isArray(reviews.items)) {
    return reviews;
  }

  return {
    ...reviews,
    items: reviews.items.map((item: any) => ({
      ...item,
      title: prepareTranslation(item.title) || '',
      icon: prepareFile(item.icon) || '',
    })),
  };
};

const preparePackages = (packages: TPackages) => {
  if (packages && Array.isArray(packages.items)) {
    packages.items = packages.items.map((item: any) => ({
      ...item,
      image: prepareFile(item.imageId),
      title: prepareTranslation(item.title),
      priceUnit: prepareTranslation(item.priceUnit),
      badge: prepareTranslation(item.badge),
      shortDescription: prepareTranslation(item.shortDescription),
      fullDescription: prepareTranslation(item.fullDescription),
    }));
  }

  return packages;
};

const getPageMeta = (page: TPage, languageCode: string, siteConfig: any) => {
  const defaultMeta = siteConfig?.common || {};
  const defaultSocialMeta = siteConfig?.socialMeta || {};

  const pageMeta = page?.meta || {};
  const pageSocialMeta = page?.socialMeta || {};
  const getTranslation = (trKey: string) =>
    trKey ? getByLanguage(prepareTranslation(trKey), languageCode) : '';
  const getFile = (fileKey: string) =>
    fileKey ? getByLanguage<TImageCMS>(prepareFile(fileKey), languageCode)?.url || null : null;

  const title =
    getTranslation(pageMeta.titleTrKey) ||
    getTranslation(page.title) ||
    getTranslation(defaultMeta.title) ||
    page.title ||
    'Sequoia';
  const description = getTranslation(pageMeta.descriptionTrKey || defaultMeta.description);
  const keywords = getTranslation(pageMeta.keywordsTrKey || defaultMeta.keywords);
  const socialTitle = getTranslation(pageSocialMeta.titleTrKey || defaultSocialMeta.title);
  const socialDescription = getTranslation(
    pageSocialMeta.descriptionTrKey || defaultSocialMeta.description
  );
  const ogFileUrl = getFile(pageSocialMeta.ogFileKey || defaultSocialMeta.og);
  const vkFileUrl = getFile(pageSocialMeta.vkFileKey || defaultSocialMeta.vk);

  const head = [`<title>${title}</title>`];
  const metaImages = {
    ogFileKey: ogFileUrl,
    vkFileKey: vkFileUrl,
  };
  if (description) {
    head.push(`<meta name="description" content="${description}">`);
  }
  if (keywords) {
    head.push(`<meta name="keywords" content="${keywords}">`);
  }
  if (socialTitle) {
    head.push(`<meta property="og:title" content="${socialTitle}"/>`);
  }
  if (socialDescription) {
    head.push(`<meta property="og:description" content="${socialDescription}"/>`);
  }
  if (ogFileUrl) {
    head.push(`<meta property="og:image" content="${ogFileUrl}"/>`);
  }
  if (vkFileUrl) {
    head.push(`<meta property="vk:image" content="${ogFileUrl}"/>`);
  }
  return { title, head: head.join('\n'), metaImages };
};

function generateCmsPagesForRouter(pages: any, languageCode: string, siteConfig: any) {
  return pages.map((page: TPage) => {
    const dataObject = {
      appContainer: null,
      page,
      ...getPageMeta(page, languageCode, siteConfig),
    };
    return {
      path: page.path,
      component: {
        name: `page__${page._id}`,
        template: `${page.content}`,
        exact: page.path === '/',
      },
      dataObject,
    };
  });
}

function generateFilePages(pages: TPage[] | undefined) {
  if (!pages?.length) {
    return;
  }

  const filePages: Record<any, any> = {};
  pages
    .filter((page) => page.shouldBeFile)
    .forEach((page) => {
      filePages[page.path] = prepareFile(page.fileKey);
    });

  return filePages;
}

function processArchives(archives: any, pages: any) {
  const archiveUrlsById = archives.reduce((acc: any, archive: any) => {
    acc[archive._id] = prependWithBeBaseUrl(archive.folderUrl);
    return acc;
  }, {});

  const archiveUrlsByPath: Record<string, any> = {};
  pages
    .filter((page: any) => page.shouldBeArchive)
    .forEach((page: any) => {
      archiveUrlsByPath[page.path] = archiveUrlsById[page.archiveId];
    });
  const archivePaths = Object.keys(archiveUrlsByPath);
  return { archiveUrls: archiveUrlsById, archiveUrlsByPath, archivePaths };
}

const processSiteConfig = async (siteConfig: any) => {
  if (siteConfig.loader) {
    siteConfig.loader = prepareFile(siteConfig.loader);
    const promises = [];
    for (const lang in siteConfig.loader) {
      if (siteConfig.loader.hasOwnProperty(lang)) {
        promises.push(
          api.loader.fetchLoaderData(siteConfig.loader[lang].url).catch((err) => {
            log.error('fetchLoaderData ->', err);
          })
        );
      }
    }
    const loadersData = await Promise.all(promises).catch(() => {
      // skip if loader doesn't exist
      return '';
    });

    let index = 0;
    for (const lang in siteConfig.loader) {
      if (siteConfig.loader.hasOwnProperty(lang)) {
        siteConfig.loader[lang].data = loadersData[index];
        index++;
      }
    }
  }

  if (!siteConfig.appStores) {
    siteConfig.appStores = {
      links: {
        android: '',
        ios: '',
        default: '',
      },
      patha: '',
      scheme: '',
    };
  }

  if (siteConfig.smartAppBanner?.icon) {
    siteConfig.smartAppBanner.icon = prepareFile(siteConfig.smartAppBanner.icon);
  }

  siteConfig.wlSpecials.QSTvBackground = prepareFile(siteConfig.wlSpecials.QSTvBackground);
  siteConfig.wlSpecials?.QSVodBackground?.forEach((source: any) => {
    source.image = prepareFile(source.imageId);
  });

  if (siteConfig.channelCollections?.length) {
    siteConfig.channelCollections.forEach((collection: TChannelCollection, i: number) => {
      siteConfig.channelCollections[i].description = prepareTranslation(
        siteConfig.channelCollections[i]?.description
      );
      siteConfig.channelCollections[i].genre = prepareTranslation(
        siteConfig.channelCollections[i]?.genre
      );
      siteConfig.channelCollections[i].title = prepareTranslation(
        siteConfig.channelCollections[i]?.title
      );
      siteConfig.channelCollections[i].channels = [];
      siteConfig.channelCollections.sort(
        (a: TChannelCollection, b: TChannelCollection) => a.sorting - b.sorting
      );
    });
  }

  siteConfig.genreIconDefault = prepareFile(siteConfig.genreIconDefault?.imageId);

  if (siteConfig.genreIcons?.length) {
    siteConfig.genreIcons.forEach((genre: TGenreIcon, i: number) => {
      siteConfig.genreIcons[i].image = prepareFile(genre.imageId);
      siteConfig.genreIcons[i].translation = prepareTranslation(genre.translationId);
    });
  }

  if (siteConfig.logo) {
    siteConfig.logo.light = prepareFile(siteConfig.logo?.light);
    siteConfig.logo.dark = prepareFile(siteConfig.logo?.dark);
  }

  return siteConfig;
};

const isEmptyObject = (obj: Record<string, any>) => !obj || Object.keys(obj).length === 0;

export const loadCmsData = async () => {
  const cmsData = global.cmsData;

  const result = await Promise.all([
    api.cms.getAllComponents(),
    api.cms.getAllContentBlocks(),
    api.cms.getAllFiles(),
    api.cms.getAllTranslations(),
    api.cms.getAllArchives(),

    api.cms.getSiteConfig(),
    api.cms.getBranding2(),
    api.cms.getAllPages(),
    api.cms.getAuthAndReg(),

    api.cms.getHeader(),
    api.cms.getFooter(),
    api.cms.getLanguages(),
    api.cms.getVodSourcesBe(),
    api.cms.getQSConfig(),
    api.cms.getBrandingMethods(),
    api.cms.getPackages(),
    api.cms.getPersonalArea(),
    api.cms.getReviews(),
    api.cms.getSeoRules(),
    api.cms.getRedirects(),

    api.appInfo.getAppInfo(),
  ]).catch((err) => {
    log.error('loadCmsData ->', err);
    throw err;
  });

  const changesFromBe = result
    .slice(0, -1)
    .some((res) => res && (!isEmptyObject(res) || res.length));

  if (!changesFromBe) {
    return;
  }

  const [
    allComponents,
    _contentBlocks,
    _files,
    _translations,
    _archives,

    _siteConfig,
    _branding2,
    pagesData,
    _authAndReg,

    _header,
    _footer,
    { languages: _languages, defaultLanguage },
    _vodSourcesBe,
    _QSConfig,
    brandingMethods,
    _packages,
    _personalArea,
    _reviews,
    _seoRules,
    _redirects,

    _appInfo,
  ] = result;

  // _translations
  const translations = !isEmptyObject(_translations) ? _translations : global.cmsData.translations;
  cmsData.translations = translations || {};

  // _files
  const files = !isEmptyObject(_files) ? _files : global.cmsData.files;
  cmsData.files = files;

  // _contentBlocks
  const contentBlocks = !isEmptyObject(_contentBlocks)
    ? _contentBlocks
    : global.cmsData.contentBlocks;
  cmsData.contentBlocks = contentBlocks;

  // allComponents
  cmsData.allComponents =
    allComponents && allComponents.length ? allComponents : global.cmsData.allComponents;
  cmsData.components = prepareComponents(cmsData.allComponents);

  // _siteConfig
  if (!isEmptyObject(_siteConfig)) {
    siteConfig = await processSiteConfig(_siteConfig);
  }

  // branding2
  const branding2 = !isEmptyObject(_branding2) ? _branding2 : global.cmsData.branding2;
  const theme: 'light' | 'dark' = branding2?.colorPalette2?.darkTheme ? 'dark' : 'light';
  const page404 = {
    contentBlock: prepareContentBlock(branding2?.page404?.contentBlockId),
    image: prepareFile(branding2?.page404?.imageId),
  };

  // pagesData
  const pages = pagesData?.pages || global.cmsData.pages;
  const pageFolders = pagesData?.pageFolders || global.cmsData.pageFolders;

  // languages
  let languages;
  if (_languages && _languages.length) {
    languages = _languages.filter((lang: any) => lang.isOnSite);
    cmsData.languages = languages;
  } else {
    languages = cmsData.languages?.filter((lang: any) => lang.isOnSite);
  }

  //archives
  const archives = _archives.length ? _archives : global.cmsData.archives;

  const locales = (cmsData.locales = languages
    .map((language: any) => language.locale)
    .filter((locale: any) => !!locale));

  // authAndReg
  const authAndReg = !isEmptyObject(_authAndReg) ? _authAndReg : global.cmsData.authAndReg;
  if (_authAndReg || _translations) {
    authAndReg.contentBlock = prepareContentBlock(authAndReg.contentBlockId);
    authAndReg.checkboxes = authAndReg.checkboxes.map((checkbox: any) => ({
      ...checkbox,
      contentBlock: prepareContentBlock(checkbox.contentBlockId),
    }));
    authAndReg.image = prepareFile(authAndReg.imageId);
    authAndReg.logo = prepareFile(authAndReg.logoId);
    authAndReg.eula = prepareContentBlock(authAndReg.eulaId);
    authAndReg.reportProblem = prepareContentBlock(authAndReg.reportProblemId);

    if (authAndReg.registration) {
      authAndReg.registration.contentBlock = prepareContentBlock(
        authAndReg.registration.contentBlockId
      );

      authAndReg.registration.checkboxes?.forEach((checkbox: any) => {
        checkbox.contentBlock = prepareContentBlock(checkbox.contentBlockId);
      });
    }

    if (authAndReg.restorePassword) {
      authAndReg.restorePassword.contentBlock = prepareContentBlock(
        authAndReg.restorePassword?.contentBlockId
      );
    }

    if (authAndReg.regAcquiring) {
      const {
        disclaimerBlockId,
        advertisementTextBlockId,
        controlSubscriptionsOnlyOnSmotreshkaBlockId,
        disclaimerInChannelsListBlockId,
      } = authAndReg.regAcquiring;

      authAndReg.regAcquiring = {
        ...authAndReg.regAcquiring,
        disclaimer: prepareContentBlock(disclaimerBlockId),
        advertisementText: prepareContentBlock(advertisementTextBlockId),
        controlSubscriptionsOnlyOnSmotreshka: prepareContentBlock(
          controlSubscriptionsOnlyOnSmotreshkaBlockId
        ),
        disclaimerInChannelsList: prepareContentBlock(disclaimerInChannelsListBlockId),
      };
    }

    if (authAndReg.trial) {
      Object.keys(authAndReg.trial).forEach((key) => {
        if (authAndReg.trial[key].variables) {
          authAndReg.trial[key].translationDict = prepareTranslation(
            authAndReg.trial[key].translationId
          );
        } else if (typeof authAndReg.trial[key] === 'string') {
          authAndReg.trial[key] = {
            translationDict:
              key === 'disclaimer'
                ? prepareContentBlock(authAndReg.trial.disclaimer)
                : prepareTranslation(authAndReg.trial[key]),
            translationId:
              key === 'disclaimer' ? authAndReg.trial.disclaimer : authAndReg.trial[key],
          };
        } else {
          authAndReg.trial[key].translationDict =
            key === 'disclaimer'
              ? prepareContentBlock(authAndReg.trial.disclaimer.translationId)
              : prepareTranslation(authAndReg.trial[key].translationId);
        }
      });
    }
  }

  // _header && _footer
  const header = _header ? _header : global.cmsData.header;
  const footer = _footer ? _footer : global.cmsData.footer;

  // _appInfo
  const appInfo = _appInfo ? _appInfo : global.cmsData.appInfo;

  const QSConfig = !isEmptyObject(_QSConfig)
    ? prepareQSConfig(_QSConfig)
    : prepareQSConfig(global.cmsData.QSConfig);
  cmsData.QSConfig = QSConfig;

  const vodSourcesBe = _vodSourcesBe?.sources.length
    ? prepareVodSourceBe(_vodSourcesBe.sources)
    : global.cmsData.vodSourcesBe;
  cmsData.vodSourcesBe = cloneDeep(vodSourcesBe);

  if (brandingMethods && brandingMethods.length) {
    cmsData.brandingMethods = brandingMethods;
  }

  const packages = !isEmptyObject(_packages) ? preparePackages(_packages) : cmsData.packages;
  cmsData.packages = cloneDeep(packages);

  const seoRules =
    _seoRules && _seoRules.length ? prepareSeoRules(_seoRules) : global.cmsData.seoRules;

  const redirects =
    _redirects && _redirects.length ? prepareRedirects(_redirects) : global.cmsData.redirects;

  const personalArea = !isEmptyObject(_personalArea) ? _personalArea : global.cmsData.personalArea;
  if (personalArea) {
    personalArea.personalDataContentBlock = prepareContentBlock(
      personalArea.personalDataContentBlockId
    );
    personalArea.profileContentBlock = prepareContentBlock(personalArea.profileContentBlockId);
  }

  const reviews = !isEmptyObject(_reviews) ? prepareReviews(_reviews) : global.cmsData.reviews;
  cmsData.reviews = cloneDeep(reviews);

  const cmsPages: Record<string, any> = {};
  for (const locale of locales) {
    cmsPages[locale] = generateCmsPagesForRouter(pages, locale, siteConfig);
  }

  const { archiveUrls, archiveUrlsByPath, archivePaths } = processArchives(archives, pages);

  return {
    defaultLanguage: defaultLanguage || global.cmsData.defaultLanguage,
    allComponents: cmsData.allComponents,
    appInfo,
    archives,
    archiveUrls,
    archiveUrlsByPath,
    archivePaths,
    authAndReg,
    branding2,
    brandingMethods: cmsData.brandingMethods,
    cmsPages,
    contentBlocks,
    components: cmsData.components,
    favicons: prepareFaviconsPaths(siteConfig.favicons),
    files,
    filePages: generateFilePages(pages),
    footer,
    header,
    languages: cmsData.languages,
    locales: cmsData.locales,
    pages,
    page404,
    pageFolders,
    packages,
    personalArea,
    reviews,
    QSConfig,
    siteConfig,
    styles: cmsData.styles,
    theme,
    translations,
    vodSourcesBe,
    seoRules,
    redirects,
  };
};

export const getGtmCounterId = () =>
  process.env.NODE_ENV === 'production' && !ENABLE_CYPRESS_MOCK
    ? GTM_COUNTERS[getEnvVars().wlAppId]
    : '';

export const getGtmCounterUrl = () => {
  const gtmCounterId = getGtmCounterId();
  if (gtmCounterId) {
    return `https://www.googletagmanager.com/ns.html?id=${gtmCounterId}`;
  }
  return '';
};
