<template>
  <transition name="fade-ease-out-default" appear>
    <div class="premium-page-popup offers-popup">
      <div class="premium-page-popup-close" @click="$emit('closePopup')">
        <IconSVG :svg="IconCross" />
      </div>

      <div class="premium-page-popup-inner">
        <div class="premium-page-popup-title">
          <span v-html="getTranslation('offer_package')" />
          «<span v-html="offerName" />»
        </div>

        <div
          v-for="genre in getMappedGenres(offerGenres)"
          :key="genre.id + genre.title"
          class="offers-popup-package"
        >
          <div class="package-name">
            <div class="package-name-icon">
              <IconSVG :svg="IconAir" :svg-path="getGenreIconPath(genre)" />
            </div>

            <div class="package-name-info">
              <div class="h3" v-text="genre.title" />
              <div>
                <span v-text="offerGenresFiltered[genre.id].length" />
                &#32;
                <span v-html="getChannelsWord(offerGenresFiltered[genre.id].length)" />
              </div>
            </div>
          </div>

          <div class="package-channels-list">
            <div
              v-for="channel in offerGenresFiltered[genre.id]"
              :key="channel.id"
              class="package-channels-item"
            >
              <div class="package-channels-item-img">
                <img :src="getChannelUrl(channel)" :alt="getChannelTitle(channel)" />
              </div>
              <div class="package-channels-item-name" v-text="getChannelTitle(channel)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import IconSVG from 'src/components/IconSVG.vue';
import IconCross from 'src/svg/cross.svg';
import { Prop } from 'vue-property-decorator';
import { TChannelEnhanced } from 'src/api/channels/types';
import { getChannelLogo, getChannelTitle } from 'src/utils/channel';
import PremiumCommon from 'src/components/premium-page/PremiumCommon';

@Component({
  methods: { getChannelTitle, getChannelLogo },
  components: { IconSVG },
})
export default class OffersPopup extends PremiumCommon {
  IconCross = IconCross;

  @Prop()
  offerName!: string;

  @Prop()
  offerGenres!: Record<string, TChannelEnhanced[]>;

  get offerGenresFiltered() {
    return Object.keys(this.offerGenres).reduce(
      (acc: Record<string, TChannelEnhanced[]>, genre) => {
        const filteredChannels = this.offerGenres[genre].filter((channel) => channel.shouldInclude);
        if (filteredChannels.length > 0) {
          acc[genre] = filteredChannels;
        }
        return acc;
      },
      {}
    );
  }

  getChannelUrl(channel: TChannelEnhanced, height = 200) {
    const url = getChannelLogo(channel);
    if (!url) {
      return '';
    }

    return `${url}?width=${Math.round((height * 16) / 9)}&height=${height}`;
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';

.offers-popup {
  .offers-popup-item {
    width: 100%;
  }

  .offers-popup-package {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 7.4%;

    .package-name {
      display: flex;
      flex-wrap: wrap;
      width: 33.8%;

      @include mobile-and-desktop-s {
        width: 100%;
        margin-bottom: 48px;
      }

      @include mobile-and-tablet {
        margin-bottom: 32px;
      }

      .package-name-icon {
        height: 64px;
        margin-right: 32px;
        color: var(--c-light-brand);

        @include mobile-and-tablet {
          height: 48px;
          margin-right: 16px;
        }

        .icon {
          width: 64px;
          height: 64px;

          @include mobile-and-tablet {
            width: 48px;
            height: 48px;
          }
        }
      }

      .package-name-info {
        width: calc(100% - 96px);

        .h3 {
          margin-bottom: 0;
          color: var(--c-dark-font-primary);
        }

        span {
          color: var(--c-dark-font-secondary);
        }
      }
    }

    .package-channels-list {
      display: flex;
      flex-wrap: wrap;
      width: 66.2%;
      gap: 24px;

      @include mobile-and-desktop-s {
        width: 100%;
      }

      @include mobile {
        gap: 16px;
      }

      .package-channels-item {
        width: calc(25% - 18px);
        max-width: 198px;
        margin-bottom: 24px;

        @include mobile {
          width: calc(50% - 8px);
        }

        .package-channels-item-img {
          width: 100%;
          margin-bottom: 8px;
          overflow: hidden;
          border-radius: 4px;
          aspect-ratio: 16 / 9;

          img {
            display: block;
            width: 100%;
          }
        }

        .package-channels-item-name {
          color: var(--c-dark-font-primary);
        }
      }
    }
  }
}
</style>
