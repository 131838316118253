<template>
  <div class="video-block">
    <div
      v-observe-visibility="visibilityOptions()"
      class="video-block-title slide-animation"
      v-html="getTranslation('premium_videoblock_title')"
    />
    <div class="video-block-video">
      <video
        ref="video"
        preload="metadata"
        :controls="videoPlaying"
        muted
        src="https://www.appsloveworld.com/wp-content/uploads/2018/10/sample-mp4-video.mp4"
      />
      <div v-if="!videoPlaying" class="video-play" @click="playVideo">
        <IconSVG size="32" :svg="IconPlay" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import PremiumCommon from 'src/components/premium-page/PremiumCommon';
import { Ref } from 'vue-property-decorator';
import IconSVG from 'src/components/IconSVG.vue';
import IconPlay from 'src/svg/player/play.svg';

@Component({
  components: { IconSVG },
})
export default class PremiumVideoBlock extends PremiumCommon {
  videoPlaying = false;
  IconPlay = IconPlay;

  @Ref('video')
  refVideo?: HTMLVideoElement;

  playVideo() {
    this.videoPlaying = true;
    this.refVideo?.play();
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';
@import 'src/styles/pages/premium-page/premium-common';

.video-block {
  position: relative;
  width: 100%;
  margin-bottom: 12%;

  @include desktop {
    top: -8vh;
  }

  @include mobile {
    margin-bottom: 15%;
  }

  .video-block-title {
    width: 100%;
    margin: 0 auto 64px;
    color: var(--c-dark-font-primary);
    text-align: center;
    @include heading1;

    @include tablet-l {
      margin: 0 auto 32px;
    }

    @include mobile {
      padding: 0 24px;
      text-align: left;
      @include heading3;
    }
  }

  .video-block-video {
    position: relative;
    width: 100%;
    max-width: 1440px;
    padding: 0 64px;
    margin: 0 auto;

    @include tablet {
      padding: 0 48px;
    }

    @include mobile {
      padding: 0;
    }

    video {
      width: 100%;
    }

    .video-play {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: var(--z-2);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 64px;
      height: 64px;
      margin: auto;
      color: var(--c-light-100);
      cursor: pointer;
      background-color: var(--alpha-light-6);
      border-radius: 50%;
    }
  }
}
</style>
