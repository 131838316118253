<template>
  <footer class="footer">
    <a class="logo-footer" target="_blank" href="https://smotreshka.tv">
      <ImageWithHash
        src="/public/images/premium-page/logos/smotreshka-masked.svg"
        alt="Смотрёшка"
      />
    </a>

    <div class="footer-contacts">
      <div class="footer-contacts-phone" v-html="getTranslation('premium_connect_by_number')" />
      <span v-if="phone" class="footer-contacts-phone" v-text="phone" />
      <span v-if="email" v-text="email" />
    </div>

    <div class="footer-socials">
      <a class="button button-action dark" target="_blank" href="#">
        <ImageWithHash src="/public/images/icons/social/vk.svg" alt="" />
      </a>
      <a class="button button-action dark" target="_blank" href="#">
        <ImageWithHash src="/public/images/icons/social/ig.svg" alt="" />
      </a>
      <a class="button button-action dark" target="_blank" href="#">
        <ImageWithHash src="/public/images/icons/social/fb.svg" alt="" />
      </a>
      <a class="button button-action dark" target="_blank" href="#">
        <ImageWithHash src="/public/images/icons/social/ok.svg" alt="" />
      </a>
      <a class="button button-action dark" target="_blank" href="#">
        <ImageWithHash src="/public/images/icons/social/tg.svg" alt="" />
      </a>
    </div>
    <div class="footer-button">
      <ButtonDefault class="header-button mb-16" @click="$emit('showSubscribePopup')">
        <span v-html="getTranslation('subscribe')" />
      </ButtonDefault>
      <a :href="`tel:${phone}`" class="footer-button-phone" v-text="phone" />
    </div>
  </footer>
</template>

<script lang="ts">
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import { SequoiaComponent } from 'src/mixins';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ImageWithHash from 'src/components/ui/ImageWithHash.vue';

@Component({
  components: {
    ImageWithHash,
    ButtonDefault,
  },
})
export default class PremiumFooter extends SequoiaComponent {
  @Prop()
  phone?: string;

  @Prop()
  email?: string;
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';

.footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 8% 0;
  color: var(--c-dark-font-primary);
  text-align: center;

  @include mobile {
    padding: 10% 0 90px;
  }

  .logo-footer {
    margin-bottom: 24px;

    img {
      max-width: 283px;

      @include mobile {
        max-width: 142px;
      }
    }
  }

  .footer-contacts {
    width: 100%;
    margin-bottom: 16px;

    @include mobile {
      margin-bottom: 24px;
      font-size: 14px;

      .footer-contacts-phone {
        display: none;
      }
    }

    span {
      display: block;
      width: 100%;
      @include heading4;

      @include mobile {
        @include heading5;
      }
    }
  }

  .footer-socials {
    display: flex;
    flex-wrap: wrap;
    color: var(--c-light-100);

    @include mobile {
      margin-bottom: 48px;
    }

    a {
      width: 48px;
      height: 48px;
      margin: 0 4px;

      img {
        height: 24px;
        margin: 4px;
        filter: contrast(0);
      }
    }
  }

  .footer-button {
    z-index: var(--z-header);
    display: none;
    width: 100%;
    padding: 16px 24px;
    background-color: var(--c-dark-200);

    a {
      color: var(--c-light-100);
    }

    @include mobile {
      position: fixed;
      bottom: 0;
      left: 0;
      display: block;
    }

    &::v-deep .button {
      width: 100%;
    }
  }
}
</style>
