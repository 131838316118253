import { render, staticRenderFns } from "./PremiumPage.vue?vue&type=template&id=18e7b152&scoped=true&"
import script from "./PremiumPage.vue?vue&type=script&lang=ts&"
export * from "./PremiumPage.vue?vue&type=script&lang=ts&"
import style0 from "src/styles/pages/premium-page/premium-page.scss?vue&type=style&index=0&id=18e7b152&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18e7b152",
  null
  
)

export default component.exports