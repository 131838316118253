<template>
  <div class="offer-channels-graph" :class="theme">
    <div v-for="genre in getMappedGenres(offerGenres)" :key="genre.id + genre.title" class="item">
      <div class="genre-icon">
        <IconSVG :svg="IconAir" :svg-path="getGenreIconPath(genre)" size="48" />
      </div>

      <div class="info">
        <div class="genre-title" v-text="genre.title" />
        <div class="progressbar">
          <span v-text="getIncludeChannels(offerGenres[genre.id])" />
          <div class="line">
            <div class="fill" :style="`width: ${getPercentChannels(genre.id)}%`" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { TChannelEnhanced } from 'src/api/channels/types';
import PremiumCommon from 'src/components/premium-page/PremiumCommon';

@Component
export default class OfferChannelsGraph extends PremiumCommon {
  @Prop()
  offerGenres!: Record<string, TChannelEnhanced[]>;

  @Prop()
  allGenres!: Record<string, TChannelEnhanced[]>;

  @Prop()
  themeForced?: 'light' | 'dark';

  get theme() {
    return this.themeForced || this.$store.theme;
  }

  getPercentChannels(genreId: string) {
    return (
      (this.getIncludeChannels(this.offerGenres[genreId]) / this.allGenres[genreId]?.length) * 100
    );
  }

  getIncludeChannels(channels: TChannelEnhanced[]) {
    return channels?.filter((channel) => channel.shouldInclude)?.length;
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.offer-channels-graph {
  display: none;
  flex-wrap: wrap;
  gap: 5%;

  @include mobile-and-tablet {
    display: flex;
  }

  .item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 32px;

    @include tablet {
      width: 47.5%;
    }

    @include mobile {
      width: 47.5%;
      margin-bottom: 16px;
    }

    .genre-icon {
      width: 64px;
      margin-right: 16px;
      color: var(--c-light-brand);

      @include tablet-l {
        width: 48px;
      }

      @include mobile {
        display: none;
      }
    }

    .info {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% - 80px);

      @include mobile {
        width: 95%;
      }
    }

    .title {
      width: 100%;
      color: var(--c-dark-font-secondary);
    }

    .progressbar {
      display: flex;
      align-items: center;
      width: 100%;

      span {
        margin-right: 8px;
        color: var(--c-dark-font-primary);
      }

      .line {
        position: relative;
        width: 100%;
        height: 4px;
        background-color: var(--alpha-dark-3);
        border-radius: 2px;

        .fill {
          position: absolute;
          right: 0;
          left: 0;
          height: 100%;
          background-color: var(--c-light-brand);
          border-radius: 2px;
        }
      }
    }
  }
}
</style>
