<template>
  <div
    v-observe-visibility="visibilityOptions({ intersection: { rootMargin: '0% 0% -30% 0%' } })"
    class="channels-list"
  >
    <div
      v-for="channel in channels"
      :key="channel.id"
      class="channel"
      :class="{ disabled: isDisabledChannel(channel.id) }"
    >
      <img :src="getChannelUrl(channel)" :alt="getChannelTitle(channel)" />
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { TChannelEnhanced } from 'src/api/channels/types';
import { selectors } from 'src/store/selectors';
import { getChannelLogo, getChannelTitle } from 'src/utils/channel';
import { Prop } from 'vue-property-decorator';
import PremiumCommon from 'src/components/premium-page/PremiumCommon';

const CHANNEL_LIST_POSTER_HEIGHT = 38;

@Component({
  methods: { getChannelTitle, getChannelLogo },
})
export default class ChannelsList extends PremiumCommon {
  @Prop()
  selectedChannels!: string[];

  get channels() {
    return selectors.tvChannels.allChannelsSelector(this.$store);
  }

  getChannelUrl(channel: TChannelEnhanced, height = CHANNEL_LIST_POSTER_HEIGHT) {
    const url = getChannelLogo(channel);
    if (!url) return '';

    return `${url}?width=${Math.round((height * 16) / 9)}&height=${height}`;
  }

  isDisabledChannel(channel: string) {
    return !this.selectedChannels.includes(channel);
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';

.channels-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  max-width: 1440px;
  padding: 0 64px;
  margin: 48px auto 12%;
  gap: 8px;

  &.enter {
    .channel {
      opacity: 1;

      &:nth-child(2n) {
        transition: opacity 0.9s ease;
        transition-delay: 0.15s;
      }

      &:nth-child(3n) {
        transition: opacity 1s ease;
        transition-delay: 0.25s;
      }

      &:nth-child(5n) {
        transition: opacity 0.8s ease;
        transition-delay: 0.4s;
      }
    }
  }

  @include desktop-s {
    margin: 48px auto 13%;
  }

  @include mobile-and-tablet {
    display: none;
  }

  .channel {
    position: relative;
    opacity: 0;
    transition: opacity 0.3s ease;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: var(--z-2);
      width: 100%;
      height: 100%;
      background-color: var(--c-light-font-primary);
      opacity: 0;
      transition: opacity 0.3s;
    }

    &.disabled {
      &::after {
        opacity: 1;
      }
    }

    img {
      position: relative;
      z-index: var(--z-1);
      max-width: 65px;
    }
  }
}
</style>
