<template>
  <div class="premium-layout">
    <slot />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';

@Component
export default class PremiumLayout extends SequoiaComponent {}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700,900&display=swap');
</style>

<style>
body {
  height: auto;
  overflow-y: visible;
}
</style>
