<template>
  <div class="vod-offer slide-animation">
    <div class="img">
      <img :src="image" :alt="name" />
    </div>

    <div class="info">
      <div v-if="icon" class="logo">
        <img :src="icon" :alt="name" />
      </div>

      <div class="name" v-html="name" />

      <div class="price">
        <span class="number" v-text="price" />
        <span v-html="getTranslation('offer_union')" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { SequoiaComponent } from 'src/mixins';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class VodOffer extends SequoiaComponent {
  @Prop()
  name!: string;

  @Prop()
  image!: string;

  @Prop()
  price!: number;

  @Prop()
  icon!: string;
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';
@import 'src/styles/pages/premium-page/premium-common';

.vod-offer {
  position: relative;
  width: 45%;
  max-height: 228px;
  overflow: hidden;
  border-radius: 6px;

  @include tablet {
    width: calc(50% - 6px);
  }

  @include mobile {
    width: 100%;
  }

  .img {
    width: 100%;

    img {
      display: block;
      width: 100%;
    }
  }

  .info {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    padding: 9%;
    color: var(--c-dark-font-primary);

    @include tablet-l {
      padding: 8%;
    }

    @include tablet-s {
      padding: 5%;
    }

    .logo {
      margin-bottom: auto;
    }

    .name {
      width: 100%;
      margin-bottom: 8px;
      @include heading4;
    }

    .price {
      .number {
        margin-right: 4px;
        @include heading3;
      }
    }
  }
}
</style>
