<template>
  <div class="offers-block">
    <div class="tv-offer-selector">
      <SelectorSingleDesktop
        v-if="selectorItems.length > 1"
        theme-forced="dark"
        :items="selectorItems"
        :selected="selectedType"
        @select="selectType"
      />
    </div>

    <div v-if="offersBySelectedType && offersBySelectedType.length" class="tv-packages-slider">
      <ContentSlider
        type="legacy"
        theme-forced="dark"
        :highlight-visible="false"
        :count-slides-change-step="1"
        :count-slide="offersBySelectedType.length"
        class="slider"
      >
        <div v-for="offer in offersBySelectedType" :key="offer.id" class="tv-package-offer-slide">
          <div
            class="tv-package-offer"
            :class="{ active: offer.selected }"
            @click="selectOffer(offer)"
          >
            <BadgeSequoia
              v-if="offer.badge"
              :text="offer.badge"
              :type="offer.selected ? 'accent' : ''"
            />
            <div class="offer-name">
              <div v-html="offer.name" />
              <div v-if="offer.vod" class="offer-vod" v-html="offer.vod" />
              <div class="offer-price">
                <span v-text="offer.promoPrice || offer.price" />
                <span v-if="offer.promoPrice" class="offer-price-old" v-text="offer.price" />
                <span class="offer-price-currency" v-html="getTranslation('offer_union')" />
              </div>
            </div>
            <div class="offer-info">
              <div v-if="offer.channels.length" class="offer-info-item">
                <IconSVG :svg="IconTV" />
                <div
                  class="offer-info-counter channels-counter"
                  @click.stop="showOfferPopup(offer)"
                >
                  <span
                    v-html="
                      getTranslation('channelsCount').replace(
                        '%channelCount%',
                        offer.channels.length.toString()
                      )
                    "
                  />
                </div>
              </div>

              <div v-if="offer.tvBox" class="offer-info-item">
                <IconSVG :svg="IconStb" />
                <div class="offer-info-counter">
                  <span v-html="getTranslation('compare_offers_tv_stb')" />
                </div>
              </div>

              <div v-if="offer.internetSpeed" class="offer-info-item">
                <IconSVG :svg="IconSpeed" />
                <div class="offer-info-counter">
                  <span>
                    <span v-text="offer.internetSpeed" />
                    <span v-html="getTranslation('internet_speed_union')" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContentSlider>
    </div>

    <div class="compare-block">
      <div class="compare-action" @click="$emit('showComparePopup')">
        <IconSVG size="32" :svg="IconCompare" />
        <span v-html="getTranslation('compare_offers_link_text')" />
      </div>

      <OfferChannelsGraph
        v-if="selectedOfferGenres && selectedAllGenres"
        :offer-genres="selectedOfferGenres"
        :all-genres="selectedAllGenres"
        theme-forced="dark"
      />
    </div>

    <ChannelsList :selected-channels="selectedChannels" />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Prop } from 'vue-property-decorator';
import { selectors } from 'src/store/selectors';
import { TPremiumPageOfferEnhanced } from 'src/api/cms/types';
import ContentSlider from 'src/components/ui/ContentSlider.vue';
import BadgeSequoia from 'src/components/ui/BadgeSequoia.vue';
import ChannelsList from 'src/components/premium-page/ChannelsList.vue';
import OfferChannelsGraph from 'src/components/premium-page/OfferChannelsGraph.vue';
import SelectorSingleDesktop from 'src/components/ui/selector-single/SelectorSingleDesktop.vue';
import IconSVG from 'src/components/IconSVG.vue';
import IconTV from 'src/svg/tv.svg';
import IconStb from 'src/svg/stb.svg';
import IconSpeed from 'src/svg/speed.svg';
import IconCompare from 'src/svg/compare.svg';

@Component({
  components: {
    ContentSlider,
    IconSVG,
    BadgeSequoia,
    ChannelsList,
    OfferChannelsGraph,
    SelectorSingleDesktop,
  },
})
export default class OffersBlock extends SequoiaComponent {
  IconTV = IconTV;
  IconStb = IconStb;
  IconCompare = IconCompare;
  IconSpeed = IconSpeed;

  selectedType = '';

  @Prop()
  offers!: Record<string, TPremiumPageOfferEnhanced[]>;

  get selectedOfferByType() {
    if (!this.offers || !this.selectedType) {
      return null;
    }

    const select = Object.keys(this.offers[this.selectedType])
      .map(Number)
      .filter((offer) => this.offers[this.selectedType][offer]?.selected)[0];

    return this.offers[this.selectedType][select];
  }

  get selectedOfferGenres() {
    return this.selectedOfferByType?.offerGenres;
  }

  get selectedAllGenres() {
    return this.selectedOfferByType?.allGenres;
  }

  get selectedChannels() {
    return this.selectedOfferByType?.channels || [];
  }

  get channels() {
    return selectors.tvChannels.allChannelsSelector(this.$store);
  }

  get selectorItems() {
    return Object.keys(this.offers).map((offerType, index) => ({
      index: offerType,
      number: index,
      text: this.getTranslation(`offer_${offerType}`),
    }));
  }

  get offersBySelectedType() {
    return this.offers[this.selectedType];
  }

  mounted() {
    this.selectedType = Object.keys(this.offers)?.[0];
    const offer = this.offers[Object.keys(this.offers)?.[0]]?.[0];

    if (offer) {
      this.$emit('selectOffer', {
        offerId: offer.id,
        showPopupChannel: false,
        offerType: offer.type,
      });
    }
  }

  selectType(item: string) {
    this.selectedType = item;
    this.selectOffer(this.offers[this.selectedType][0]);
  }

  selectOffer(offer: TPremiumPageOfferEnhanced, showPopupChannel = false) {
    this.$emit('selectOffer', { offerId: offer.id, showPopupChannel, offerType: offer.type });
  }

  showOfferPopup(offer: TPremiumPageOfferEnhanced) {
    const selectedOffer = this.offers[offer.type].find((offer) => offer.selected);
    if (selectedOffer && selectedOffer.offerId === offer.offerId) {
      this.$emit('showOffersPopup');
    } else {
      this.selectOffer(offer, true);
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';

.tv-offer-selector {
  display: flex;
  justify-content: center;
  width: 100%;

  @include mobile {
    width: 94%;
    margin: 0 auto;

    &::v-deep .item {
      line-height: 1.1;
    }
  }
}

.tv-packages-slider {
  position: relative;
  width: 100%;
  max-width: 1440px;
  padding: 0 64px;
  margin: 0 auto;
  overflow: hidden;

  @include tablet {
    padding: 0 48px;
  }

  @include mobile {
    padding: 0 10px;
  }

  .tv-packages-title {
    margin-bottom: 16px;
    color: var(--c-dark-font-secondary);
    @include heading4;

    @include tablet {
      margin-bottom: 24px;
    }

    @include mobile {
      padding-left: 14px;
      margin-bottom: 24px;
    }
  }

  &::v-deep .content-slider {
    margin: 0;

    .inner {
      overflow: visible;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 2%;
        z-index: var(--z-1);
        width: 100%;
        height: 100%;
        background: var(--c-dark-150);
      }

      &::before {
        left: -100%;
        box-shadow: 12px 0 12px -8px rgba(0, 0, 0, 0.7);
      }

      &::after {
        left: 100%;
        box-shadow: -12px 0 12px -8px rgba(0, 0, 0, 0.7);
      }

      &.last-step {
        &::after {
          box-shadow: none;
        }
      }

      &.first-step {
        &::before {
          box-shadow: none;
        }
      }
    }

    .slider-items {
      align-items: flex-end;
    }

    .nav-button {
      z-index: var(--z-2);

      @include mobile {
        display: block;
      }

      &.active {
        @include mobile {
          .nav-button-icon {
            opacity: 1;
          }
        }
      }

      .nav-button-icon {
        @include mobile {
          width: 32px;
          height: 32px;
        }
      }
    }

    .nav-button.right {
      z-index: var(--z-2);

      .nav-button-icon {
        left: 100%;
        @include mobile {
          left: 50%;
        }
      }
    }

    .nav-button.left {
      .nav-button-icon {
        left: 0;
        @include mobile {
          left: 50%;
        }
      }
    }

    .nav-button-icon {
      color: var(--c-light-100);
      background-color: var(--c-light-brand);
      border-radius: 50%;

      svg {
        width: 76%;
        height: 76%;
        margin: 12%;
      }
    }
  }

  .tv-package-offer-slide {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 324px;
    margin-right: 24px;
    cursor: pointer;

    &:first-child {
      @include tablet-l {
        margin-left: 0;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      .tv-package-offer {
        background-color: var(--c-dark-700);
      }
    }

    .tv-package-offer {
      position: relative;
      display: flex;
      width: 100%;
      height: 200px;
      color: var(--c-dark-font-primary);
      background-color: var(--c-dark-500);
      border-radius: 4px;

      &.active {
        color: var(--alpha-light-8);
        background-color: var(--c-light-brand);

        .offer-price span {
          color: var(--alpha-light-7);
        }

        .offer-vod {
          color: var(--alpha-light-8);
        }

        .offer-info-item {
          .offer-info-counter {
            color: var(--alpha-light-8);

            &.channels-counter {
              span {
                border-bottom-color: var(--alpha-light-8);
              }
            }
          }
        }
      }
    }

    .badge {
      position: absolute;
      top: -12px;
      left: 8px;

      @include tablet-l {
        top: -16px;
        height: 24px;
        padding-right: 12px;
        padding-left: 12px;
      }
    }

    .offer-name {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-content: flex-start;
      width: 60%;
      padding: 16px;

      .offer-vod {
        @include body2;

        color: var(--c-dark-font-primary);
      }

      .offer-price {
        width: 100%;
        margin-top: auto;
        @include heading3;

        .offer-price-old {
          color: var(--c-dark-font-secondary);
          text-decoration: line-through;
          @include heading6;
        }

        .offer-price-currency {
          display: block;
          width: 100%;
          color: var(--c-dark-font-secondary);
          @include body1;
        }
      }
    }

    .offer-info {
      width: 40%;
      padding: 16px;
      background-color: var(--alpha-light-4);

      .offer-info-item {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 12px;

        .offer-info-counter {
          width: 100%;
          margin-top: 4px;
          color: var(--c-dark-font-primary);
          @include body2;

          &.channels-counter {
            cursor: pointer;

            span {
              border-bottom-color: var(--alpha-dark-8);
              border-bottom-style: dashed;
              border-bottom-width: 1px;

              &:hover {
                border-bottom-style: solid;
              }
            }
          }
        }
      }
    }
  }
}

.compare-block {
  position: relative;
  width: 100%;
  max-width: 1440px;
  padding: 0 64px;
  margin: 36px auto 0;

  @include tablet {
    padding: 0 48px;
  }

  @include mobile {
    padding: 0 10px;
  }

  @include desktop-s {
    margin-top: 28px;
  }

  @include tablet-l {
    margin-top: 32px;
    margin-bottom: 64px;
  }

  @include mobile {
    padding: 0 14px;
  }

  .compare-action {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 36px;
    color: var(--c-dark-font-primary);
    cursor: pointer;

    @include tablet-l {
      margin-bottom: 32px;
    }

    .icon {
      margin-right: 12px;
    }
  }
}
</style>
