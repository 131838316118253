import { SequoiaComponent } from 'src/mixins';
import Component from 'vue-class-component';
import { ObserveVisibility } from 'vue-observe-visibility';
import { TTranslations } from 'src/store/translations/types';
import { TImageCMS } from 'src/store/common/types';
import { TGenre } from 'src/store/tv-channels/types';
import IconAir from 'src/svg/genres/air.svg';
import IconSVG from 'src/components/IconSVG.vue';
import { selectors } from 'src/store/selectors';
import { TChannelEnhanced } from 'src/api/channels/types';

@Component({
  directives: { ObserveVisibility },
  components: { IconSVG },
})
export default class PremiumCommon extends SequoiaComponent {
  IconAir = IconAir;

  get shouldUseTvApiV2() {
    return this.$store.siteConfig?.tv?.shouldUseTvApiV2;
  }

  get genresFilterField() {
    return this.shouldUseTvApiV2 ? 'id' : 'title';
  }

  get genres() {
    return selectors.tvChannels.genresSelector(this.$store);
  }

  getMappedGenres(genres: Record<string, TChannelEnhanced[]>) {
    return this.genres.reduce((acc: TGenre[], genre: TGenre) => {
      const filteredChannels =
        genres?.[genre[this.genresFilterField]]?.filter((channel) => channel.shouldInclude) || [];
      if (filteredChannels?.length > 0) {
        acc.push({ id: genre.id || genre.title, title: genre.title });
      }
      return acc;
    }, []);
  }

  getGenreIconPath(genre: TGenre) {
    return selectors.tvChannels.genreIconSelector(this.$store, genre);
  }

  getFileUrl(image: TTranslations<TImageCMS> | string) {
    return typeof image === 'object' ? image[this.languageCode]?.url : '';
  }

  visibilityOptions(options = {}) {
    return {
      callback: (isVisible: boolean, entry: IntersectionObserverEntry) => {
        if (isVisible) {
          entry.target.classList.add('enter');
        }
      },
      once: true,
      ...options,
    };
  }
}
