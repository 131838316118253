<template>
  <header class="header">
    <div class="header-inner">
      <ButtonAction
        theme-forced="dark"
        data-cy="burger"
        size="medium"
        :class="{ pressed: burgerMenuVisible }"
        class="burger"
        :icon="burgerMenuVisible ? IconCross : IconBurger"
        @click="toggleMenu"
      />

      <a v-if="logo" class="header-logo" @click.prevent="$emit('logoLinkAction')">
        <img :src="logo" alt="logo" />
      </a>

      <menu :class="{ open: burgerMenuVisible }" class="header-menu">
        <a
          v-for="item in menu"
          :key="item.title"
          :class="[{ active: item.section === activeSection }, item.section, activeSection]"
          :href="`#${item.section}`"
          class="header-menu-item"
          @click.prevent="goTo(item.section)"
          v-html="item.title"
        />
      </menu>

      <div class="header-phone mr-24" v-text="phone" />
      <ButtonDefault class="header-button" @click="$emit('showSubscribePopup')">
        <span v-html="getTranslation('subscribe')" />
      </ButtonDefault>
    </div>
  </header>
</template>

<script lang="ts">
import { SequoiaComponent } from 'src/mixins';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import ButtonAction from 'src/components/ui/buttons/ButtonAction.vue';
import IconBurger from 'src/svg/burger.svg';
import IconCross from 'src/svg/cross.svg';

@Component({
  components: {
    ButtonDefault,
    ButtonAction,
  },
})
export default class PremiumHeader extends SequoiaComponent {
  burgerMenuVisible = false;
  IconBurger = IconBurger;
  IconCross = IconCross;

  menuItems = [
    {
      title: this.getTranslation('premium_menu_advantage'),
      section: 'advantage',
    },
    {
      title: this.getTranslation('premium_menu_offers'),
      section: 'offers',
    },
    {
      title: this.getTranslation('premium_menu_additional_offers'),
      section: 'additionals',
    },
    {
      title: this.getTranslation('premium_menu_how_watch'),
      section: 'how',
    },
  ];

  @Prop()
  logo?: string;

  @Prop()
  siteLink?: string;

  @Prop()
  phone?: string;

  @Prop()
  activeSection?: string;

  @Prop({ default: true })
  additionalOfferExist?: boolean;

  get menu() {
    return this.menuItems.filter((item) =>
      item.section === 'additionals' ? this.additionalOfferExist : true
    );
  }

  toggleMenu() {
    this.burgerMenuVisible = !this.burgerMenuVisible;
  }

  goTo(section: string) {
    this.$emit('scrollTo', section);
    this.toggleMenu();
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-header);
  display: flex;
  width: 100%;
  height: 72px;
  background-color: var(--c-dark-200);
  box-shadow: 0 4px 8px 0 var(--alpha-light-6);

  .header-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    max-width: 1440px;
    height: 100%;
    padding: 0 64px;
    margin: 0 auto;

    @include tablet-l {
      padding: 0 50px;
    }

    @include mobile {
      padding: 0;
    }
  }

  .header-logo {
    max-width: 165px;
    margin-right: 20px;
    cursor: pointer;

    img {
      max-height: 62px;
    }

    @include mobile {
      margin: 0 auto;
    }
  }

  .header-button {
    @include mobile-and-tablet-s {
      margin-left: auto;
    }

    @include mobile {
      display: none;
    }
  }

  .header-phone {
    @include body2;

    color: var(--c-dark-font-primary);

    @include mobile-and-desktop-s {
      margin-left: auto;
    }

    @include mobile-and-tablet-s {
      display: none;
    }
  }

  .burger {
    display: none;

    @include mobile-and-desktop-s {
      display: flex;
    }

    @include mobile {
      position: absolute;
      left: 8px;
    }
  }

  .header-menu {
    padding: 0;
    margin-right: auto;

    .header-menu-item {
      padding-bottom: 4px;
      margin: 0 12px;
      color: var(--c-dark-font-secondary);
      @include body1;

      &.active {
        color: var(--c-dark-font-primary);
        border-bottom: 2px solid var(--c-light-brand);
      }
    }

    @include mobile-and-desktop-s {
      position: absolute;
      top: 71px;
      left: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      max-height: 0;
      padding: 0 60px;
      margin: 0;
      overflow: hidden;
      background-color: var(--c-dark-200);
      transition: max-height 0.2s;

      .header-menu-item {
        width: 100%;
        height: 48px;
        padding: 0 16px;
        margin: 0;
        line-height: 48px;
        color: var(--c-dark-font-primary);
        border-radius: 4px;

        &.active {
          background-color: var(--alpha-dark-3);
          border-bottom: transparent;
        }

        &:first-child {
          margin-top: 16px;
        }

        &:last-child {
          margin-bottom: 44px;
        }
      }

      &.open {
        max-height: 400px;
      }
    }

    @include mobile {
      padding: 0 40px;
    }
  }
}
</style>
