<template>
  <transition :name="subscribeAnimation" appear @after-enter="isOpen = true">
    <div class="premium-page-popup subscribe-popup" :class="{ opened: isOpen }" @click.self="close">
      <div class="premium-page-popup-inner">
        <div class="premium-page-popup-close" @click="close">
          <IconSVG :svg="IconCross" />
        </div>

        <div class="subscribe-form">
          <div
            class="subscribe-form-title"
            v-html="getTranslation('premium_subscribe_form_title')"
          />
          <form
            v-if="!isSendSubscribe"
            autocomplete="off"
            novalidate
            @submit.prevent="sendSubscribe"
          >
            <InputText
              v-model="subscribe.app_name"
              themeForced="dark"
              required
              :label="getTranslation('premium_subscribe_form_name')"
              input-category="floating"
            />

            <InputText
              v-model="subscribe.app_phone"
              type="tel"
              required
              themeForced="dark"
              :label="getTranslation('premium_subscribe_form_phone')"
              input-category="floating"
            />

            <div class="captcha mt-24">
              <div ref="recaptcha" />
            </div>

            <ButtonDefault
              :disabled="disabledButton"
              :isLoading="isLoading"
              size="medium"
              class="mt-24 mb-24"
            >
              <span v-html="getTranslation('premium_subscribe_form_btn')" />
            </ButtonDefault>
          </form>

          <div
            v-else
            class="success-form-text"
            v-html="getTranslation('premium_subscribe_form_success_text')"
          />
          <div
            v-if="linkToLk"
            class="subscribe-form-text"
            v-html="getTranslation('premium_subscribe_form_text').replace('%link%', linkToLk)"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { SequoiaComponent } from 'src/mixins';
import Component from 'vue-class-component';
import IconSVG from 'src/components/IconSVG.vue';
import IconCross from 'src/svg/cross.svg';
import InputText from 'src/components/ui/input/InputText.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import { getDeviceFlags } from 'src/utils/platform-detector';
import { Prop, Ref } from 'vue-property-decorator';
import { sendPremiumPageSubscribe } from 'src/api/premium-page';
import { cleanupPhone } from 'src/utils/phone';
import { CORRECT_PHONE_LENGTH } from 'src/constants';

@Component({
  components: { IconSVG, InputText, ButtonDefault },
})
export default class SubscribeForm extends SequoiaComponent {
  IconCross = IconCross;

  @Prop()
  linkToLk?: string;

  @Prop()
  providerEmail?: string;

  @Prop()
  providerSlug?: string;

  @Ref('recaptcha')
  refRecaptcha!: HTMLInputElement;

  subscribe = {
    app_name: '',
    app_phone: '',
    operator_email: this.providerEmail || '',
    operator_name: this.providerSlug || '',
    'g-recaptcha-response': '',
  };
  isLoading = false;
  isOpen = false;
  isSendSubscribe = false;

  recaptchaId = '6LfX9uciAAAAAEfgnaG8TbmASkQMm0M3Z6fgquHT';

  async mounted() {
    this.isSendSubscribe = false;
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=explicit&onload=recaptchaOnLoadCallback`;
    document.head.appendChild(script);

    if (typeof window !== 'undefined') {
      window.recaptchaOnLoadCallback = () => {
        window.grecaptcha.ready(() => {
          window.grecaptcha.render(this.refRecaptcha, {
            sitekey: this.recaptchaId,
            theme: 'dark',
            callback: (id) => {
              this.subscribe['g-recaptcha-response'] = id;
            },
          });
        });
      };
    }
  }

  get isMobile() {
    return getDeviceFlags().isMobile && window.innerWidth < 600;
  }

  get subscribeAnimation() {
    return this.isMobile ? 'menu-dropdown-container-mobile' : 'fade-ease-out-default';
  }

  get disabledButton() {
    return (
      this.subscribe.app_name.length === 0 ||
      cleanupPhone(this.subscribe.app_phone).length < CORRECT_PHONE_LENGTH ||
      this.subscribe['g-recaptcha-response'].length === 0
    );
  }

  close() {
    this.isOpen = false;
    this.$nextTick(() => {
      this.$emit('closePopup');
    });
  }

  async sendSubscribe() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    const result = await sendPremiumPageSubscribe({ data: this.subscribe });
    if (result) {
      this.isLoading = false;
      this.isSendSubscribe = true;
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';

.premium-page-popup {
  &::v-deep {
    &.subscribe-popup {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      background: var(--alpha-light-7);

      .premium-page-popup-inner {
        position: relative;
        max-width: 500px;
        padding: 64px 48px 32px;
        color: var(--c-dark-font-primary);
        background: var(--c-dark-150);
      }

      .premium-page-popup-close {
        top: 8px;
        right: 8px;
      }
    }

    @include tablet {
      &.subscribe-popup {
        .premium-page-popup-inner {
          max-width: 438px;
        }
      }
    }

    @include mobile {
      &.subscribe-popup {
        background: none;

        &.opened {
          background: var(--alpha-light-7);
        }

        .premium-page-popup-inner {
          position: absolute;
          bottom: 0;
          padding: 64px 24px 32px;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
      }
    }
  }
}

.subscribe-form {
  display: flex;
  flex-wrap: wrap;

  .subscribe-form-title {
    margin-bottom: 24px;
    @include heading3;
  }

  .subscribe-form-text {
    &::v-deep {
      .link.nav-underlined {
        color: var(--c-dark-font-primary);

        &:hover {
          color: var(--c-light-link-hover);
        }
      }
    }
  }

  .input-block {
    width: 100%;
  }

  .button {
    width: 100%;
  }

  .captcha {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  form {
    width: 100%;
  }

  .success-form-text {
    width: 100%;
    padding: 80px 0;
    text-align: center;
    @include body1;

    &::v-deep {
      b {
        display: block;
        width: 100%;
        margin-bottom: 10px;
        @include heading4;
      }
    }
  }

  @include mobile {
    .subscribe-form-title {
      font-size: 28px;
    }
  }
}

.menu-dropdown-container-mobile-enter-active,
.menu-dropdown-container-mobile-leave-active {
  transition: transform 0.3s ease-in-out;
}

.menu-dropdown-container-mobile-enter,
.menu-dropdown-container-mobile-leave-to {
  transform: translateY(100%);
}
</style>
