<template>
  <PremiumLayout
    v-if="provider || !$store.premiumPage.loaded"
    data-cy="premium-page"
    class="premium-page"
  >
    <PremiumHeader
      v-if="provider"
      :site-link="provider.siteLink"
      :logo="logo"
      :phone="provider.phone"
      :active-section="activeSection"
      :additional-offer-exist="additionalOffers.length > 0"
      @showSubscribePopup="openSubscribePopup(provider.registrationLink)"
      @scrollTo="scrollTo"
      @logoLinkAction="openSubscribePopup(provider.siteLink)"
    />

    <LoaderSpinner v-if="!$store.premiumPage.loaded" class="loader-spinner" />

    <main v-else class="premium-page-main-container">
      <div
        id="advantage"
        ref="advantage"
        v-observe-visibility="checkActiveMenu"
        :class="{ safari: isSafari }"
        class="tv-block"
      >
        <div class="tv-block-tv">
          <div
            ref="tvTitle"
            v-observe-visibility="visibilityOptions()"
            :class="{ hide: !showTvTitle, enter: tvTitle }"
            class="tv-block-title slide-animation"
            v-html="getTranslation('premium_main_title')"
          />
          <div v-observe-visibility="visibilityOptions()" class="tv-block-glow" />
          <div
            ref="tvActive"
            v-observe-visibility="visibilityOptions()"
            class="tv-block-tv-active"
          />
          <div v-observe-visibility="visibilityOptions()" class="tv-block-gradient" />
          <div
            v-observe-visibility="visibilityOptions()"
            :style="{ transform: `translateY(${channel1Offset}px)` }"
            class="tv-block-channels-1"
          />
          <div
            v-observe-visibility="visibilityOptions()"
            :style="{ transform: `translateY(${channel2Offset}px)` }"
            class="tv-block-channels-2"
          />
          <div
            v-observe-visibility="visibilityOptions()"
            :style="{ transform: `translateY(${channel3Offset}px)` }"
            class="tv-block-channels-3"
          />
        </div>
        <div
          v-observe-visibility="visibilityOptions({ intersection: { rootMargin: '-5% 0%' } })"
          class="tv-block-text slide-animation"
        >
          <p v-html="getTranslation('premium_main_description')" />
        </div>
      </div>

      <PremiumBanners
        v-if="provider && provider.banners.length"
        v-observe-visibility="visibilityOptions()"
        :source-banners="provider.banners"
        @showSubscribePopup="provider.applicationForm ? openPopup('showSubscribePopup') : false"
      />

      <div v-observe-visibility="visibilityOptions()" class="device-block slide-animation">
        <div class="device-block-col">
          <div class="device-block-col-title" v-html="getTranslation('premium_device_left')" />
          <ImageWithHash src="/public/images/premium-page/devices.png" alt="" />
        </div>

        <div class="device-block-col">
          <div class="device-block-col-title" v-html="getTranslation('premium_device_right')" />
          <div class="device-block-col-supports">
            <div
              class="device-block-col-supports-title"
              v-html="getTranslation('premium_device_support')"
            />
            <div class="support-device"><IconSVG size="32" :svg="IconAirplay" /> AirPlay</div>
            <div class="support-device"><IconSVG size="32" :svg="IconCast" /> Chromecast</div>
          </div>
        </div>
      </div>

      <PrivilegeList />

      <div ref="platform" class="platform-block">
        <div class="platform-block-content">
          <div
            v-observe-visibility="
              visibilityOptions({ intersection: { rootMargin: '0% 0% -30% 0%' } })
            "
            :style="{ transform: `scale(${scalePlatform})` }"
            class="platform-block-img"
          />
          <div
            v-observe-visibility="
              visibilityOptions({ intersection: { rootMargin: '0% 0% -30% 0%' } })
            "
            class="platform-block-title slide-animation"
            v-html="getTranslation('premium_platform_block_title')"
          />
          <div
            v-observe-visibility="
              visibilityOptions({ intersection: { rootMargin: '0% 0% -20% 0%' } })
            "
            class="platform-block-text slide-animation"
            v-html="getTranslation('premium_platform_block_text')"
          />
          <div
            v-observe-visibility="
              visibilityOptions({ intersection: { rootMargin: '0% 0% -10% 0%' } })
            "
            class="platform-block-platforms slide-animation"
          >
            <div class="platforms-item">
              <ImageWithHash
                src="/public/images/premium-page/platforms/appstore.svg"
                alt="appstore"
              />
            </div>
            <div class="platforms-item">
              <ImageWithHash
                src="/public/images/premium-page/platforms/google-play-badge.svg"
                alt="google-play"
              />
            </div>
            <div class="platforms-item">
              <ImageWithHash
                src="/public/images/premium-page/platforms/android-tv.svg"
                alt="android-tv"
              />
            </div>
            <div class="platforms-item">
              <ImageWithHash
                src="/public/images/premium-page/platforms/lg-smart-tv.svg"
                alt="lg-smart-tv"
              />
            </div>
            <div class="platforms-item">
              <ImageWithHash
                src="/public/images/premium-page/platforms/samsung-smart-tv.svg"
                alt="samsung-smart-tv"
              />
            </div>
            <div class="platforms-item">
              <ImageWithHash
                src="/public/images/premium-page/platforms/apple-tv.svg"
                alt="apple-tv"
              />
            </div>
            <div class="platforms-item">
              <ImageWithHash
                src="/public/images/premium-page/platforms/app-gallery.svg"
                alt="app-gallery"
              />
            </div>
            <div class="platforms-item">
              <ImageWithHash src="/public/images/premium-page/platforms/huawei.svg" alt="huawei" />
            </div>
            <div class="platforms-item">
              <ImageWithHash src="/public/images/premium-page/platforms/vidaa.svg" alt="vidaa" />
            </div>
            <div class="platforms-item">
              <ImageWithHash
                src="/public/images/premium-page/platforms/sber-store.svg"
                alt="sber"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="tv-package-block">
        <SimpleTextBlock
          v-observe-visibility="visibilityOptions()"
          class="tv-package-info"
          :title="getTranslation('premium_package_block_title')"
          :text="getTranslation('premium_package_block_text')"
        />

        <div id="offers" ref="offers" v-observe-visibility="checkActiveMenu" class="tv-packages">
          <OffersBlock
            v-if="offersGroupByType"
            :offers="offersGroupByType"
            @selectOffer="selectOffer"
            @showOffersPopup="openPopup('showOffersPopup')"
            @showComparePopup="openPopup('showComparePopup')"
          />
        </div>

        <div
          v-if="additionalOffers.length"
          id="additionals"
          ref="additionals"
          v-observe-visibility="checkActiveMenu"
          class="tv-packages-more"
        >
          <SimpleTextBlock
            v-observe-visibility="visibilityOptions()"
            class="tv-packages-more-text"
            :title="getTranslation('premium_package_more_title')"
            :text="getTranslation('premium_package_more_text')"
          />

          <div class="tv-packages-more-list">
            <AdditionalOffer
              v-for="offer in additionalOffers"
              :key="offer.offerId"
              v-observe-visibility="visibilityOptions()"
              :name="offer.name"
              :price="offer.price"
              :promo-price="offer.promoPrice"
              :channels-count="offer.channels.length"
              :offer-channels-names="offer.offerChannelsNames"
              @selectAdditionalOffer="selectAdditionalOffer(offer.id, offer.offerGenres)"
            />
          </div>
        </div>
      </div>

      <div v-if="vodOffers.length" class="vod-block">
        <SimpleTextBlock
          v-observe-visibility="visibilityOptions()"
          class="vod-block-info"
          :title="getTranslation('premium_vod_block_title')"
          :text="getTranslation('premium_vod_block_text')"
        />

        <div class="vod-block-inner">
          <div class="vod-block-wrapper">
            <div class="vod-list">
              <VodOffer
                v-for="(offer, i) in vodOffers"
                :key="offer.offerId + i"
                v-observe-visibility="visibilityOptions()"
                :name="offer.name"
                :image="getFileUrl(offer.image)"
                :icon="getFileUrl(offer.icon)"
                :price="offer.price"
              />
            </div>
          </div>

          <div class="vod-device">
            <div
              v-observe-visibility="visibilityOptions({ intersection: { rootMargin: '-50% 0%' } })"
              class="vod-device-stage-2"
            />
          </div>
        </div>
      </div>

      <div v-if="false">
        <PremiumVideoBlock />
      </div>

      <div id="how" ref="how" v-observe-visibility="checkActiveMenu" class="more-platforms-block">
        <div
          v-observe-visibility="visibilityOptions()"
          class="more-platforms-title slide-animation"
          v-html="getTranslation('premium_platforms_title')"
        />
        <PlatformsList @visibilityOptions="visibilityOptions" />
      </div>

      <PremiumFooter
        v-if="provider"
        :phone="provider.phone"
        :email="provider.email"
        @showSubscribePopup="openSubscribePopup(provider.registrationLink)"
      />
    </main>

    <OffersPopup
      v-if="popups.showOffersPopup"
      :offer-name="selectedOffer.name"
      :offer-genres="channelsFromPopup"
      @closePopup="closePopup('showOffersPopup')"
    />

    <ComparePopup
      v-if="popups.showComparePopup"
      :offers="offers"
      @closePopup="closePopup('showComparePopup')"
    />

    <SubscribeForm
      v-if="provider && popups.showSubscribePopup"
      :link-to-lk="provider.personalAreaLink"
      :provider-email="provider.email"
      :provider-slug="provider.slug"
      @closePopup="closePopup('showSubscribePopup')"
    />
  </PremiumLayout>

  <Error404Page v-else />
</template>

<script lang="ts">
import Component from 'vue-class-component';
import throttle from 'lodash/throttle';
import { Dictionary } from 'vue-router/types/router';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import PremiumLayout from 'src/layouts/PremiumLayout.vue';

import OffersPopup from 'src/components/premium-page/OffersPopup.vue';
import ComparePopup from 'src/components/premium-page/ComparePopup.vue';
import OffersBlock from 'src/components/premium-page/OffersBlock.vue';
import SubscribeForm from 'src/components/premium-page/SubscribeForm.vue';
import SimpleTextBlock from 'src/components/premium-page/SimpleTextBlock.vue';
import PremiumFooter from 'src/components/premium-page/PremiumFooter.vue';
import PremiumHeader from 'src/components/premium-page/PremiumHeader.vue';
import VodOffer from 'src/components/premium-page/VodOffer.vue';
import PremiumBanners from 'src/components/premium-page/PremiumBanners.vue';
import PlatformsList from 'src/components/premium-page/PlatformsList.vue';
import AdditionalOffer from 'src/components/premium-page/AdditionalOffer.vue';
import PrivilegeList from 'src/components/premium-page/PrivilegeList.vue';
import PremiumVideoBlock from 'src/components/premium-page/PremiumVideoBlock.vue';

import { selectors } from 'src/store/selectors';
import { matchUrlProtocolWithLocationProtocol } from 'src/utils/url';
import { actions } from 'src/store/actions';

import IconSVG from 'src/components/IconSVG.vue';
import IconCast from 'src/svg/cast.svg';
import IconAirplay from 'src/svg/airplay.svg';
import { Ref } from 'vue-property-decorator';

import { ObserveVisibility } from 'vue-observe-visibility'; // TODO update plugin during refactoring to composition api
import { BROWSERS, getBrowserName, getDeviceFlags } from 'src/utils/platform-detector';
import groupBy from 'lodash/groupBy';
import * as api from 'src/api';
import PremiumCommon from 'src/components/premium-page/PremiumCommon';
import { TChannelEnhanced } from 'src/api/channels/types';
import ImageWithHash from 'src/components/ui/ImageWithHash.vue';
import { getChannelTitle } from 'src/utils/channel';
import { preparePremiumPages } from 'src/utils/server-utils';
import { ShowcaseChannel } from 'src/models/ts/offer';

import logger from 'src/utils/logger';
import Error404Page from 'src/pages/error/Error404Page.vue';
import { TPremiumPageOffer } from 'src/api/cms/types';
const log = logger('fe-settings');

const VOD_OFFER_TYPES = 'vod';
const ADDITIONAL_OFFER_TYPES = 'additional';

@Component({
  components: {
    Error404Page,
    ImageWithHash,
    LoaderSpinner,
    PremiumLayout,
    IconSVG,
    OffersPopup,
    ComparePopup,
    OffersBlock,
    SubscribeForm,
    SimpleTextBlock,
    PremiumFooter,
    PremiumHeader,
    VodOffer,
    PremiumBanners,
    PlatformsList,
    AdditionalOffer,
    PrivilegeList,
    PremiumVideoBlock,
  },
  directives: { ObserveVisibility },
})
export default class PremiumPage extends PremiumCommon {
  IconAirplay = IconAirplay;
  IconCast = IconCast;

  showTvTitle = true;
  tvTitle = false;

  scalePlatform = 2;

  channel1Offset = 0;
  channel2Offset = 0;
  channel3Offset = 0;

  isSafari = getBrowserName() === BROWSERS.safari;
  onThrottledScroll = throttle(this.handleScroll, this.isSafari ? 300 : 0);

  popups: Record<string, boolean> = {
    showOffersPopup: false,
    showComparePopup: false,
    showSubscribePopup: false,
  };

  channelsFromPopupType = '';
  offerGenresFromPopup: Record<string, TChannelEnhanced[]> = {};

  activeSection = '';

  @Ref('tvTitle')
  refTvTitle?: HTMLDivElement;

  @Ref('tvActive')
  refTvActive!: HTMLDivElement;

  @Ref('platform')
  refPlatform!: HTMLDivElement;

  @Ref('advantage')
  refAdvantage!: HTMLDivElement;

  @Ref('offers')
  refOffers!: HTMLDivElement;

  @Ref('additionals')
  refAdditional_offers!: HTMLDivElement;

  @Ref('how')
  refHowToWatch!: HTMLDivElement;

  get provider() {
    return this.$store.premiumPage.provider;
  }

  get offersGroupByType() {
    return groupBy(this.mainOffers, 'type') || [];
  }

  get offers() {
    return this.provider?.offers || [];
  }

  get channelsFromPopup() {
    return this.channelsFromPopupType === VOD_OFFER_TYPES
      ? this.offerGenresFromPopup
      : this.selectedOffer?.offerGenres;
  }

  get mainOffers() {
    return this.offers.filter(
      (offer) => offer.type !== VOD_OFFER_TYPES && offer.type !== ADDITIONAL_OFFER_TYPES
    );
  }

  get vodOffers() {
    return this.offers.filter((offer) => offer.type === VOD_OFFER_TYPES);
  }

  get additionalOffers() {
    return this.offers.filter((offer) => offer.type === ADDITIONAL_OFFER_TYPES);
  }

  get selectedOffer() {
    return this.provider?.offers.find((offer) => offer.selected);
  }

  get isMobile() {
    return window.innerWidth < 600 || getDeviceFlags().isMobile;
  }

  get channels() {
    return selectors.tvChannels.allChannelsSelector(this.$store);
  }

  get logo() {
    if (!this.provider?.logo) {
      return;
    }

    return matchUrlProtocolWithLocationProtocol(
      this.getFileUrl(this.provider?.logo),
      this.$store.common.isHttps
    );
  }

  get genres() {
    return selectors.tvChannels.genresSelector(this.$store);
  }

  get channelsGroupField() {
    return this.shouldUseTvApiV2 ? 'relevantGenres[0].genreId' : 'info.metaInfo.genres[0]';
  }

  async serverPrefetch() {
    await this.loadProvider();
  }

  async mounted() {
    await this.loadProvider();
    if (this.provider) {
      window.addEventListener('scroll', this.onThrottledScroll);
    }
  }

  beforeDestroy() {
    if (this.provider) {
      window.removeEventListener('resize', this.onThrottledScroll);
    }
  }

  async loadProvider() {
    if (this.$store.premiumPage.loaded) {
      return;
    }
    await actions.tvChannels.loadTvData(this.$store);
    const slug = (this.$route.query.name as string) || '';
    const { providers } = await api.cms.getPremiumPageProvider(slug);
    let provider;

    if (providers.length) {
      provider = preparePremiumPages(providers)[0];
    }

    if (provider?.offers) {
      provider.offers = await this.loadProviderOffers(provider.offers);
      this.$store.premiumPage.provider = provider;
    }
    this.$store.premiumPage.loaded = true;
  }

  async loadProviderOffers(offers: TPremiumPageOffer[]) {
    const allGenres = groupBy(this.channels, this.channelsGroupField);

    if (allGenres.undefined) {
      delete allGenres.undefined;
    }

    return await Promise.all(
      offers
        .filter((offer) => offer.offerId)
        .map(async (offer, i) => await this.loadProviderOffer(offer, i, allGenres))
    );
  }

  async loadProviderOffer(
    offer: TPremiumPageOffer,
    i: number,
    allGenres: Dictionary<TChannelEnhanced[]>
  ) {
    let channels: ShowcaseChannel[] = [];
    try {
      const offerChannels = await api.offers.getOfferChannels(offer.offerId);
      if (offerChannels.channels.length) {
        channels = offerChannels.channels;
      }
    } catch (err) {
      log.error(err);
    }

    const offerChannelsIds = channels ? channels.map((channel) => channel.channelId) : [];
    const offerChannels = Object.values(this.channels).map((channel: TChannelEnhanced) => ({
      ...channel,
      shouldInclude: offerChannelsIds.includes(channel.id),
    }));

    const offerGenres = groupBy(offerChannels, this.channelsGroupField);

    if (offerGenres.undefined) {
      delete offerGenres.undefined;
    }

    return {
      ...offer,
      channels: offerChannelsIds,
      offerChannelsNames:
        offer.type === ADDITIONAL_OFFER_TYPES && offerChannelsIds.length > 0
          ? Object.values(this.channels)
              .filter((channel) => offerChannelsIds.includes(channel.id))
              .slice(0, 5)
              .map((channel) => getChannelTitle(channel))
              .join(', ')
          : '',
      offerGenres,
      allGenres,
      id: offer.offerId + offer.name,
      selected: i === 0,
    };
  }

  scrollTo(section: string) {
    const sectionTo = (this.$refs[section] as HTMLDivElement).offsetTop;
    window.scrollTo({
      top: sectionTo - 100,
      behavior: 'smooth',
    });
  }

  openSubscribePopup(link: string) {
    if (this.provider?.applicationForm) {
      this.openPopup('showSubscribePopup');
    } else if (typeof window === 'object' && link) {
      window.location.href = link;
    }
  }

  visibilityChanged(isVisible: boolean, entry: IntersectionObserverEntry) {
    if (isVisible) {
      entry.target.classList.add('enter');
    }
  }

  checkActiveMenu(isVisible: boolean, entry: IntersectionObserverEntry) {
    if (isVisible) {
      this.activeSection = entry.target.getAttribute('id') || '';
    }
  }

  visibilityOptions(options = {}) {
    return {
      callback: this.visibilityChanged,
      once: true,
      ...options,
    };
  }

  selectAdditionalOffer(offerId: string, offerGenres: Record<string, TChannelEnhanced[]>) {
    this.offerGenresFromPopup = offerGenres;
    this.selectOffer({ offerId, showPopupChannel: true, offerType: VOD_OFFER_TYPES });
  }

  openPopup(popup: string) {
    actions.common.lockBodyScroll(this.$store);
    this.popups[popup] = true;
  }

  closePopup(popup: string) {
    actions.common.unlockBodyScroll(this.$store);
    this.popups[popup] = false;
  }

  selectOffer({
    offerId,
    showPopupChannel,
    offerType,
  }: {
    offerId: string;
    showPopupChannel: boolean;
    offerType: string;
  }) {
    if (this.provider) {
      this.channelsFromPopupType = offerType;
      const offers = this.provider?.offers;
      this.provider.offers = offers.map((offer) => ({
        ...offer,
        selected: offer.id === offerId,
      }));
      if (showPopupChannel) {
        this.openPopup('showOffersPopup');
      }
    }
  }

  handleScroll() {
    const title = this.refTvActive?.getBoundingClientRect();

    if (!this.isMobile) {
      if (title?.top + title?.height / 2 < 0) {
        this.showTvTitle = false;
        this.tvTitle = true;
      } else {
        this.showTvTitle = true;
      }

      const platform = this.refPlatform.getBoundingClientRect();
      if (platform.top - window.innerHeight < 0 && platform.bottom > window.innerHeight) {
        this.scalePlatform = 2 + (platform.top - window.innerHeight) / platform.height;
      }
    }

    if (title?.bottom > 0 && !this.isMobile) {
      this.channel1Offset = window.scrollY * 0.3;
      this.channel2Offset = window.scrollY * 0.2;
      this.channel3Offset = window.scrollY * 0.4;
    }
  }
}
</script>

<style lang="scss" src="src/styles/pages/premium-page/premium-page.scss" scoped />
