<template>
  <div class="privilege-block">
    <div
      v-observe-visibility="visibilityOptions({ intersection: { rootMargin: '0% 0% -70% 0%' } })"
      class="active-tv"
    />

    <div class="privilege-list">
      <div
        v-observe-visibility="visibilityOptions()"
        class="privilege-list-title slide-animation"
        v-html="getTranslation('premium_privilage_list_title')"
      />

      <div class="privilege-list-items">
        <div
          v-for="item in privileges"
          :key="item.id"
          v-observe-visibility="visibilityOptions()"
          class="privilege-list-item slide-animation"
        >
          <h4 class="h4" v-html="item.title" />
          <div class="body1" v-html="item.text" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import PremiumCommon from 'src/components/premium-page/PremiumCommon';

@Component
export default class PrivilegeList extends PremiumCommon {
  privileges = [
    {
      id: 'privilage_item_1',
      title: this.getTranslation('premium_privilage_item_1_title'),
      text: this.getTranslation('premium_privilage_item_1_text'),
    },
    {
      id: 'privilage_item_2',
      title: this.getTranslation('premium_privilage_item_2_title'),
      text: this.getTranslation('premium_privilage_item_2_text'),
    },
    {
      id: 'privilage_item_3',
      title: this.getTranslation('premium_privilage_item_3_title'),
      text: this.getTranslation('premium_privilage_item_3_text'),
    },
    {
      id: 'privilage_item_4',
      title: this.getTranslation('premium_privilage_item_4_title'),
      text: this.getTranslation('premium_privilage_item_4_text'),
    },
    {
      id: 'privilage_item_5',
      title: this.getTranslation('premium_privilage_item_5_title'),
      text: this.getTranslation('premium_privilage_item_5_text'),
    },
  ];
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';
@import 'src/styles/pages/premium-page/premium-common';

.privilege-block {
  position: relative;
  width: 100%;
  min-height: 100vh;
  margin-bottom: 8.4%;
  background-image: url('/public/images/premium-page/features-background-state-1.jpg');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 92%;

  @include devices-retina {
    background-image: url('/public/images/premium-page/features-background-state-1@2x.jpg');
  }

  @include desktop-s {
    margin-bottom: 9.5%;
    background-size: 128%;
  }

  @include tablet {
    min-height: 80vh;
    background-size: 111%;
  }

  @include mobile {
    margin-bottom: 10.4%;
    background-size: 150%;
  }

  .active-tv {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('/public/images/premium-page/features-background-state-2.jpg') no-repeat center
      top;
    background-size: 92%;
    opacity: 0;
    transition: opacity 2s;

    @include devices-retina {
      background: url('/public/images/premium-page/features-background-state-2@2x.jpg') no-repeat
        center top;
      background-size: 92%;
    }

    &.enter {
      opacity: 1;
    }

    @include desktop-s {
      background-size: 128%;
    }

    @include tablet {
      background-size: 111%;
    }

    @include mobile {
      background-size: 150%;
    }
  }

  .privilege-list {
    position: relative;
    z-index: var(--z-2);
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1216px;
    padding: 64vh 64px 0;
    margin: 0 auto;

    @media (max-width: 1680px) {
      padding: 57vh 64px 0;
    }

    @include desktop-s {
      padding: 57vh 64px 0;
    }

    @include tablet {
      padding: 38.5vh 48px 0;
    }

    @include mobile {
      padding: 26vh 24px 0;
    }

    .privilege-list-title {
      width: 50%;
      padding-right: 8%;
      color: var(--c-dark-font-primary);
      word-wrap: break-word;
      @include heading2;

      @include desktop-s {
        padding-right: 5%;
      }

      @include tablet {
        padding-right: 3%;
      }

      @include mobile {
        width: 100%;
        padding-right: 0;
        margin-bottom: 11%;
      }
    }

    .privilege-list-items {
      width: 50%;
      padding-left: 1%;

      .privilege-list-item {
        margin-bottom: 48px;

        &::v-deep {
          .h4 {
            background-image: linear-gradient(94deg, #ffc000 1%, #f93 100%);
            background-clip: text;
            -webkit-text-fill-color: transparent;
            @include heading4;

            @include mobile-and-tablet {
              @include heading5;
            }
          }

          .body1 {
            margin: 8px 0 0;
            color: var(--c-dark-font-primary);
            @include headline2;

            @include mobile-and-tablet {
              @include body2;
            }
          }
        }

        @include desktop-s {
          margin-bottom: 32px;
        }

        @include mobile-and-tablet {
          margin-bottom: 28px;
        }
      }

      @include desktop-s {
        padding-left: 2%;
      }

      @include mobile {
        width: 100%;
        padding-left: 0;
      }
    }
  }
}
</style>
