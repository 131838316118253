<template>
  <div class="banners-block slide-animation">
    <HomeContentSlider v-if="banners.length > 1" theme-forced="dark" type="carousel" class="slider">
      <BannerHero
        v-for="(banner, i) in banners"
        :key="banner.name"
        class="home-banner"
        :link="!applicationForm ? banner.link : ''"
        :is-external-link="isExternalLink(banner.link)"
        :image-mobile-small="banner.imageMobileSmall"
        :image-mobile-large="banner.imageMobileLarge"
        :image-desktop="banner.imageDesktop"
        :preload="i === 0"
        @click.native="$emit('showSubscribePopup')"
      />
    </HomeContentSlider>

    <BannerHero
      v-if="banners.length === 1"
      class="home-banner one-banner"
      :link="!applicationForm ? banners[0].link : ''"
      :is-external-link="isExternalLink(banners[0].link)"
      :image-mobile-small="banners[0].imageMobileSmall"
      :image-mobile-large="banners[0].imageMobileLarge"
      :image-desktop="banners[0].imageDesktop"
      :preload="true"
      @click.native="$emit('showSubscribePopup')"
    />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { matchUrlProtocolWithLocationProtocol } from 'src/utils/url';
import { TPremiumPageBanner } from 'src/api/cms/types';
import BannerHero from 'src/components/ui/banners/BannerHero.vue';
import HomeContentSlider from 'src/components/home/HomeContentSlider.vue';
import PremiumCommon from 'src/components/premium-page/PremiumCommon';

@Component({
  components: {
    HomeContentSlider,
    BannerHero,
  },
})
export default class PremiumBanners extends PremiumCommon {
  @Prop()
  sourceBanners!: TPremiumPageBanner[];

  @Prop()
  applicationForm!: boolean;

  get banners() {
    const width16x4 = 1312; // max desktop width
    const width16x9l = 768; // max large-mobile width
    const width16x9s = 480; // max small-mobile width

    const height16x4 = Math.round((width16x4 * 4) / 16); // desktop
    const height16x9l = Math.round((width16x9l * 9) / 16); // mobile-l
    const height16x9s = Math.round((width16x9s * 9) / 16); // mobile-s
    return this.sourceBanners?.map((banner) => ({
      ...banner,
      imageDesktop: {
        src: matchUrlProtocolWithLocationProtocol(
          this.getFileUrl(banner.imageId16x4),
          this.$store.common.isHttps
        ),
        width: width16x4,
        height: height16x4,
      },
      imageMobileLarge: {
        src: matchUrlProtocolWithLocationProtocol(
          this.getFileUrl(banner.imageId16x9),
          this.$store.common.isHttps
        ),
        width: width16x9l,
        height: height16x9l,
      },
      imageMobileSmall: {
        src: matchUrlProtocolWithLocationProtocol(
          this.getFileUrl(banner.imageId16x9),
          this.$store.common.isHttps
        ),
        width: width16x9s,
        height: height16x9s,
      },
    }));
  }

  isExternalLink(link: string) {
    return link?.startsWith('http');
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/common/dimensions-variables';
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/pages/premium-page/premium-common';

.banners-block {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 0 12%;

  @include tablet-l {
    margin: 8% 0 12%;
  }

  @include mobile {
    margin: 8% 0 18%;
  }

  &::v-deep .content-slider {
    width: 100%;
  }

  .home-banner {
    width: 100%;
    max-width: $content-max-width;
    margin: 0 $gap-between-banners;
  }

  .one-banner {
    @include tablet {
      padding: 0 48px;
    }

    @include mobile {
      padding: 0 16px;
    }
  }
}
</style>
