<template>
  <div class="tv-packages-more-item slide-animation">
    <div class="tv-packages-more-item-title" v-html="name" />
    <div class="tv-packages-more-item-price">
      <b v-text="promoPrice || price" />
      <span v-if="promoPrice" class="price" v-text="price" />
      <span v-html="getTranslation('offer_union')" />
    </div>

    <div class="tv-packages-more-item-channels">
      <span v-html="getTranslation('channels_in_package')" />
      <div class="tv-packages-more-item-channels-list" v-text="tvPackagesText" />
      <button
        v-if="channelsCount > 5"
        type="button"
        class="link action-default tv-packages-more-item-channels-show-all"
        @click.prevent="$emit('selectAdditionalOffer')"
        v-html="getTranslation('show_all_channels')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { SequoiaComponent } from 'src/mixins';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class AdditionalOffer extends SequoiaComponent {
  @Prop()
  name!: string;

  @Prop()
  price!: number;

  @Prop()
  promoPrice!: number;

  @Prop()
  offerChannelsNames!: string;

  @Prop()
  channelsCount!: number;

  get tvPackagesText() {
    return `${this.offerChannelsNames}  ${this.channelsCount > 5 ? '...' : ''}`;
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/placeholders-and-mixins/media-queries';
@import 'src/styles/placeholders-and-mixins/typography';
@import 'src/styles/pages/premium-page/premium-common';

.tv-packages-more-item {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  width: calc(33.333% - 24px);
  padding: 32px 24px 24px;
  margin: 12px;
  overflow: hidden;
  color: var(--c-dark-font-primary);
  background-color: var(--c-dark-400);
  border-radius: 4px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 8px;
    margin: 0 24px 0 0;
    background-image: linear-gradient(176deg, #ffc000 1%, #f93);
  }

  @include mobile-and-tablet {
    width: 100%;
    padding: 32px 24px 24px 32px;

    &::before {
      width: 8px;
      height: 100%;
    }
  }

  @include tablet {
    flex-direction: row;
    align-items: center;
    width: 100%;
    min-height: 104px;
  }

  @include mobile {
    margin: 0 0 16px;
  }

  .tv-packages-more-item-title {
    width: 100%;
    margin-bottom: 16px;
    color: var(--c-dark-font-primary);
    @include heading5;

    @include tablet {
      order: 1;
      width: 33.33%;
    }

    @include mobile {
      order: 1;
      width: 100%;
      margin-bottom: 12px;
    }
  }

  .tv-packages-more-item-channels {
    width: 100%;
    padding-right: 3%;
    margin-top: auto;
    color: var(--c-dark-font-primary);
    @include body2;

    @include tablet {
      order: 2;
      width: 33.33%;
    }

    @include mobile {
      order: 3;
      width: 100%;
    }

    span {
      color: var(--c-dark-font-secondary);
    }

    .tv-packages-more-item-channels-list {
      margin-top: 3px;
    }
  }

  .tv-packages-more-item-channels-show-all {
    color: var(--c-dark-font-secondary);
    cursor: pointer;
    @include body2;

    span {
      border-bottom: 1px dashed var(--c-dark-font-secondary);
    }
  }

  .tv-packages-more-item-price {
    width: 100%;
    margin-bottom: 8px;
    color: var(--c-dark-font-secondary);
    @include body2;

    @include tablet {
      order: 3;
      width: 33.33%;
      text-align: right;
    }

    @include mobile {
      order: 2;
      width: 100%;
      margin-bottom: 24px;
      text-align: left;
    }

    b {
      margin-right: 4px;
      color: var(--c-dark-font-primary);
      text-align: right;
      @include heading3;
    }

    .price {
      margin-right: 4px;
      text-decoration: line-through;
      @include heading6;
    }
  }
}
</style>
